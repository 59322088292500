import axios from 'axios';
import { checkAndSetToken, handleError } from './interceptor';
import { BACK_URL, TIMEOUT } from 'apis';

/** Axios 인스턴스의 설정과 요청 및 응답 인터셉터를 관리하는 파일 **/

export const axiosInstance = axios.create({
  // `url`이 절대값이 아닌 경우 `baseURL`은 URL 앞에 붙습니다.
  // 상대적인 URL을 인스턴스 메서드에 전달하려면 `baseURL`을 설정하는 것은 편리합니다.
  baseURL: BACK_URL,
  // `timeout`은 요청이 시간 초과되기 전의 시간(밀리초)을 지정합니다.
  // 요청이 `timeout`보다 오래 걸리면 요청이 중단됩니다.
  timeout: TIMEOUT, // 기본값은 `0` (타임아웃 없음)

  // `withCredentials`은 자격 증명을 사용하여 사이트 간 액세스 제어 요청을 해야 하는지 여부를 나타냅니다.
  withCredentials: true, // 기본값: false
  headers: {
    // Accept: '*/*',
    'Content-Type': 'application/json'
  }
  // `auth`는 HTTP Basic 인증이 사용되며, 자격 증명을 제공합니다.
  // `auth`를 사용하면, `Authorization` 헤더가 설정되어 `headers`를 사용하여 설정한 기존의 `Authorization` 사용자 지정 헤더를 덮어씁니다.
  // 이 파라미터를 통해 HTTP Basic 인증만 구성할 수 있음을 참고하세요.
  // Bearer 토큰 등의 경우 `Authorization` 사용자 지정 헤더를 대신 사용합니다.
  // auth: {
  //   username: 'janedoe',
  //   password: 's00pers3cret',
  // },
});
// 요청 인터셉터
axiosInstance.interceptors.request.use(checkAndSetToken, handleError);
// 응답 인터셉터
axiosInstance.interceptors.response.use((response) => response, handleError);
