import React from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import SearchModal from 'components/Modal/SearchModal';

export default function ImageRenderer(params: CustomCellRendererProps) {
  return (
    <span className='img'>
      {/* ToDo : params.value를 받고 이미지  URL받기 */}
      {/* {params.value && ( */}
      <img
        alt={`${params.value}`}
        src={`https://s.pstatic.net/static/www/mobile/edit/20240112_1095/upload_1705057885416AaxUM.png`}
        className='img'
        style={{ width: 'auto', height: '-webkit-fill-available' }}
      />
      {/* 임시로 넣은 돋보기 모달임 ToDo : 나중에 삭제해야함 */}
      <SearchModal />
      {/* )} */}
    </span>
  );
}
