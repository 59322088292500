import React, { useState } from 'react';
import DaumPost from './DaumPost';
import { TPostCode } from '../types/address';

export default function FindAddress() {
  const [addressPopup, setAddressPopup] = useState(false);

  // 상태값 설정
  const [form, setForm] = useState<TPostCode>({
    address: '',
    zonecode: '',
  });

  // 팝업 열고 닫기
  const handleAddressPopup = () => setAddressPopup(!addressPopup);

  return (
    <>
      <button onClick={handleAddressPopup} style={{}}>
        주소검색
      </button>

      {addressPopup && (
        <DaumPost
          address={form}
          setAddress={setForm}
          handleComplete={handleAddressPopup}
        />
      )}
    </>
  );
}
