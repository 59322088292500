import { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColumnApi, PaginationChangedEvent } from 'ag-grid-community';
import { components, createGridOptions, CustomHeader, defaultColDef, idCheckboxColumnDefs } from './CommonFunction';
import { getItemsAPI } from 'apis/item/itemAPI';
import { TItemsResponseData } from 'types/api';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import ImageRenderer from './imageRenderer';
import '../../styles/agGrid.css';
import { GridApi } from 'ag-grid-enterprise';
import { Pagination } from 'antd';
import { PaginationProps } from 'antd/lib';

export default function ItemApiGrid() {
  // ** 우리 API  (test용) **//
  const [rowData, setRowData] = useState<TItemsResponseData[]>([]);
  const [columnDefs, setcolumnDefs] = useState<ColDef[]>([]);

  // AG-Grid API호출에 필요한 로직
  const onGridReadyApi = async (params: any) => {
    // 페이지 네이션 관현 코드
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setLoading(false);
    // API 불러오는 원래 코드
    try {
      // API적용
      const responseApi = await getItemsAPI();
      const responseData = responseApi.data.data;

      // Ensure responseData is an array
      if (Array.isArray(responseData)) {
        // 'id'를 기준으로 오름차순 정렬 : 대부분 O(n log n)의 시간 복잡도
        responseData.sort((a, b) => a.id - b.id);

        setRowData(responseData);
        const columnDefs = idCheckboxColumnDefs(responseData);
        console.log('@@@@@responseData', responseData, 'columnDefs', columnDefs);
        setcolumnDefs([
          {
            headerName: '', // 헤더네임 우선 안넣는걸로
            field: 'boolean',
            // cellEditor: 'agCheckboxCellEditor',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            minWidth: 20
          },
          {
            headerName: 'Image',
            field: 'image',
            cellRenderer: ImageRenderer,
            cellClass: 'imageCell',
            minWidth: 100
          },
          //   ToDo : 이거는 데이터가 어떤방식으로 표로 보여져야할것이고 데이터가 어떻게 오는지에 따라 바뀌는부분이라 추후에 해야함
          {
            field: 'Chart(데이터필요)',
            cellRenderer: 'agSparklineCellRenderer'
          },
          ...columnDefs
        ]);

        console.log('API response - responsepi - responseData : ', responseData);
        console.log('API response - responsepi - columnDefs : ', columnDefs);
      } else {
        console.error('Expected array but got:', responseData);
        setRowData([]); // Set to empty array if responseData is not an array
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onRowDoubleClicked = (event: any) => {
    // ToDo : 조회화면에서 데이터 더블클릭하면 이 데이터를 가지고 어디로 넘겨서 사용할지가 관건
    console.log('더블클릭된 데이터 : ', event.data);
  };

  // 페이지네이션 관련 코드
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(10); // 페이지당 몇개 보여줄지 상태
  const [paginationPageCur, setPaginationPageCur] = useState<number>(1); // 현재 페이지
  const [lbLastPageFound, setLbLastPageFound] = useState<boolean>(false);
  const [lbTotalPages, setLbTotalPages] = useState<number>(0);
  const [lbFilteredRecords, setLbFilteredRecords] = useState<number>(0);
  const [lbTotalRecords, setLbTotalRecords] = useState<string>('0');

  const onPageChanged = (newPage: string) => {
    if (Number(paginationPageCur) === Number(newPage)) {
      return;
    }
    setPaginationPageCur(Number(newPage));
    gridApi?.paginationGoToPage(Number(newPage) - 1);
  };
  const onBtnFirst = () => {
    gridApi?.paginationGoToFirstPage();
  };
  const onBtnLast = () => {
    gridApi?.paginationGoToLastPage();
  };
  const onBtnNext = () => {
    gridApi?.paginationGoToNextPage();
  };
  const onBtnPrevious = () => {
    gridApi?.paginationGoToPreviousPage();
  };
  const onBtnGoToPage = (newPage: number) => {
    gridApi?.paginationGoToPage(newPage);
  };

  // 페이지 변경 핸들러
  const onPageChange = (page: number) => {
    setPaginationPageCur(page);
    gridApi?.paginationGoToPage(page - 1);
  };
  // 페이지 크기 변경 핸들러
  const onPageSizeChange: PaginationProps['onShowSizeChange'] = (current, size) => {
    console.log('onPageSizeChange', size, 'paginationPageSize', paginationPageSize);
    setPaginationPageSize(size);
    if (gridApi) {
      gridApi?.paginationSetPageSize(size);
      setPaginationPageCur(1); // 페이지 크기 변경 시 첫 페이지로 이동
    }
  };

  // AG-Grid 임시 테스트용
  useEffect(() => {
    console.log('변경 : columnDefs : ', columnDefs);
  }, [columnDefs]);
  // 페이지 네이션 관련코드
  useEffect(() => {
    if (gridApi) {
      setLbLastPageFound(gridApi.paginationIsLastPageFound());
      setPaginationPageSize(gridApi.paginationGetPageSize());
      setPaginationPageCur(gridApi.paginationGetCurrentPage() + 1);
      setLbTotalPages(gridApi.paginationGetTotalPages());
      setLbFilteredRecords(gridApi.getDisplayedRowCount());
      setLbTotalRecords(String(gridApi.getDisplayedRowCount()));
      gridApi.paginationSetPageSize;
    }
    console.log('lbTotalPages', lbTotalPages, 'lbFilteredRecords', lbFilteredRecords, 'paginationPageSize', paginationPageSize);
  }, [gridApi, rowData]);

  // 페이지에 따른 데이터 불러오는 방식이 달라짐 (추후에 페이지네이션 적용하려면 API호출방식 바꿔야함)
  // useEffect(() => {
  //   // Simulate fetching data
  //   const fetchData = async () => {
  //     // Replace with your actual data fetching logic
  //     const result = await fetch(`/api/data?page=${paginationPageCur}&size=${pageSize}`);
  //     const data = await result.json();
  //     setRowData(data.items);
  //   };

  //   fetchData();
  // }, [paginationPageCur]);

  return (
    <>
      {/* API 그리드!!! */}
      <div>ANASA item API 정보 들어간 Grid</div>
      <div className='ag-theme-alpine' style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={createGridOptions({ dateFilter: true, excelExport: true })}
          onGridReady={onGridReadyApi}
          domLayout='autoHeight' // 높이 자동
          enableCellTextSelection={false} //셀 내의 텍스트를 선택할 수 있도록 설정 .true=클립보드 비활성화, 선택한 텍스트만 복사
          enterNavigatesVertically={true} // Enter 키를 누르면 아래 셀로 이동
          enterNavigatesVerticallyAfterEdit={true} // Enter 키를 누르면 아래 셀로 이동 (*AfterEdit)
          rowSelection={'multiple'} // id쪽 체크박스 여러개 할수있는 옵션!!!! (*완전 필요함)
          copyHeadersToClipboard={false} // 클립보드로 복사할 때 머리글도 포함 = true
          suppressCopySingleCellRanges={true} // 단일 셀만 있는 범위를 선택할 때 범위 대신 행을 복사하려면 true
          suppressCutToClipboard={false}
          enableRangeSelection={true} // 범위 선택을 활성화
          suppressCopyRowsToClipboard={false} // 선택한 행을 클립보드에 복사
          suppressHorizontalScroll={false} //가로 스크롤 허용 여부
          onRowDoubleClicked={onRowDoubleClicked} // 더블클릭되면 호출되는 함수
          components={components}
          // 페이지네이션 하려면 아래 주석 제거하고 적용 해야함
          // pagination={false}
          // paginationPageSize={pageSize}
        />
        {/* 페이지네이션 */}
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
          <Pagination
            showSizeChanger
            locale={{ items_per_page: '' }}
            onShowSizeChange={onPageSizeChange}
            onChange={onPageChange}
            defaultCurrent={paginationPageCur}
            pageSize={paginationPageSize}
            total={rowData.length}
          />
        </div>
      </div>
    </>
  );
}
