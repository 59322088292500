import React from 'react';

import { Form, Tabs, Upload } from 'antd';
import type { TabsProps } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import OrderTypeRegistrationInquiry from '../../../components/Register/OrderTypeRegistrationInquiry';
export default function OrderTypeRegistration() {
  const onChange = (key: string) => {
    console.log(key);
  };
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '품목정보',
      children: '품목정보 어떻게 나오는지 모르겠수다.'
    },
    {
      key: '2',
      label: '파일업로드',
      children: (
        <>
          <Form.Item label=''>
            <Form.Item name='dragger' valuePropName='fileList' getValueFromEvent={normFile} noStyle>
              <Upload.Dragger name='files' action='/upload.do'>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Support for a single or bulk upload.</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </>
      )
    }
  ];
  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <OrderTypeRegistrationInquiry />

      <Tabs onChange={onChange} type='card' defaultActiveKey='1' items={items} />
    </section>
  );
}
