import React from 'react';
import FindAddress from '../components/FindAddress';
import AlertModal from '../components/Modal/AlertModal';
import Login from '../pages/auth/Login';
import Main from '../pages/Main';
import NotFound from '../pages/NotFound';
import SalesAccountModal from '../components/Modal/SalesAccountModal';
import OrderTypeRegistration from '../pages/configuration/business/OrderTypeRegistrationInquiry';
import Loading from '../pages/configuration/modal/Loading';
import SearchMenuModal from '../components/SearchMenuModal';
import SignUp from 'pages/auth/SignUp';
import AgGrid from 'pages/AgGrid';

export const ROUTES = {
  HOME: { path: '/', label: 'home', element: <Main /> },
  LOADING: {
    path: '/config/modal/loading',
    label: 'loading',
    element: <Loading />
  },
  SEARCH_MENU: {
    path: '/config/modal/menu-search',
    label: '메뉴찾기',
    element: <SearchMenuModal />
  },
  ALERT_MODAL: {
    path: '/config/modal/alert',
    label: 'alert modal',
    element: <AlertModal errCode={'에러코드 넣어야하지^^'} /> // TODO : undefined를 나중엔 상태관리로 빼야할거같음
  },
  FIND_ADD: {
    path: '/config/modal/find-address',
    label: '주소찾기',
    element: <FindAddress />
  },
  SALES_ACCOUNT: {
    path: '/config/modal/sales-account',
    label: '판매거래처',
    element: <SalesAccountModal />
  },
  ORDERTYPE_REGISTRATION: {
    path: '/config/business/order-type-registration',
    label: '수주형태등록',
    element: <OrderTypeRegistration />
  },
  BONDTYPE_REGISTRATION: {
    path: '/config/business/bond-type-registration',
    label: '매출채권형태등록',
    element: <NotFound />
  },
  AG_GRID: {
    path: '/config/business/ag-grid',
    label: 'AG_GRID',
    element: <AgGrid />
  }
};
export const LOGIN_ROUTES = {
  // LOGIN
  LOGIN: { path: '/login', label: '로그인', element: <Login /> },
  LOGIN_AUTH: { path: '/login/auth', label: '-', element: <NotFound /> },
  FIND_PASSWORD: {
    path: '/find-password',
    label: '비밀번호찾기',
    element: <NotFound />
  },
  FIND_PASSWORD_RESET: {
    path: '/find-password/reset',
    label: '비밀번호 초기화',
    element: <NotFound />
  },
  SIGNUP: { path: '/signup', label: '회원가입', element: <SignUp /> },
  UNAUTHORIZED: {
    path: '/unauthorized',
    label: 'unauthorized',
    element: <NotFound />
  },
  NOT_FOUND: { path: '*', label: '404', element: <NotFound /> }
};
