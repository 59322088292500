import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Result
        status='404'
        title='404'
        subTitle={
          <>
            찾을수 없는 페이지 입니다.
            <br />
            요청하신 페이지가 사라졌거나,
            <br />
            존재하지 않는 페이지 입니다.
          </>
        }
        extra={
          <Button type='primary' onClick={() => navigate(-1)}>
            뒤로가기
          </Button>
        }
        style={{ width: '400px' }} // 필요에 따라 결과 컴포넌트의 너비를 조정할 수 있습니다.
      />
    </div>
  );
};

export default NotFound;
