import React, { useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select, Space } from 'antd';
import CustomModal from './CustomModal';

export default function SalesAccountModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Option } = Select;
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 }
  };

  const config = {
    rules: [
      {
        type: 'object' as const,
        required: true,
        message: '날짜를 선택해주세요'
      }
    ]
  };

  const onFinish = (fieldsValue: any) => {
    // Should format date value before submit.
    const values = {
      ...fieldsValue,
      'order-date': fieldsValue['order-date'].format('YYYY-MM-DD'),
      'due-date': fieldsValue['due-date'].format('YYYY-MM-DD')
    };
    console.log('Received values of form: ', values);
  };

  return (
    <>
      <Button type='primary' onClick={() => setIsModalOpen(true)}>
        판매거래처 Open Modal
      </Button>

      <CustomModal title='판매거래처' isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Form
          name='validate_other'
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{}}
          style={{
            maxWidth: '100%'
          }}
        >
          <Row gutter={[8, 16]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item label='품목' style={{ marginBottom: 0 }}>
                <Form.Item name='item1' rules={[{ required: true }]} style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                  <Input placeholder='' />
                </Form.Item>
                <Form.Item
                  name='item2'
                  rules={[{ required: true }]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    margin: '0 8px'
                  }}
                >
                  <Input placeholder='' />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item name='item-group' label='품목그룹' rules={[{ required: true, message: '품목그룹를 입력하세요.' }]}>
                <Input placeholder='Please input item-group' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item
                name='item-account'
                label='품목계정'
                rules={[
                  {
                    required: true,
                    message: '품목계정을 입력해주세요.'
                  }
                ]}
              >
                <Select placeholder='Please select a item-account'>
                  <Option value='product1'>상품</Option>
                  <Option value='product2'>제품</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item name='standard' label='규격'>
                <Input placeholder='Please input standard' />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item name='tax-invoice-type' label='유효'>
                <Radio.Group>
                  <Radio value='yes'>예</Radio>
                  <Radio value='no'>아니오</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item name='base-date' label='기준일' {...config}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Button>검색</Button>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={12} md={12} lg={6} xl={12}>
              <Form.Item label='판매거래처코드'>
                <Space.Compact>
                  <Form.Item
                    name='consignment-classification'
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '위탁구분을 선택해주세요.'
                      }
                    ]}
                  >
                    <Select placeholder='Please select a consignment-classification' style={{ width: '50%' }}>
                      <Option value='general'>판매거래처코드</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name={['consignment', 'input']} noStyle>
                    <Input style={{ width: '50%' }} placeholder='판매거래처코드를 입력하세요.' />
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            </Col>
          </Row>

          {/* 조회 리셋 */}
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Space>
              <Button type='primary' htmlType='submit'>
                조회
              </Button>
              <Button htmlType='reset'>초기화</Button>
            </Space>
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
}
