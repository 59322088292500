import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AlertModalProps } from '../../types/modal';

export default function AlertModal({ errCode }: AlertModalProps) {
  const [open, setOpen] = useState(false);

  const handleAlertModalOpen = () => setOpen(true);
  const handleAlertModalClose = () => setOpen(false);

  return (
    <>
      <Button type='primary' onClick={handleAlertModalOpen}>
        Open Alert Modal (ToDo: API 연결 후 에러 코드에 따라 모달 표시)
      </Button>
      <Modal
        open={open}
        onOk={handleAlertModalClose}
        onCancel={handleAlertModalClose}
        footer={[
          <Button key='submit' type='primary' onClick={handleAlertModalClose}>
            OK
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <ExclamationCircleOutlined
            style={{
              color: 'orange',
              fontSize: '2rem',
              display: 'block',
              margin: '0 auto',
            }}
          />
          <div style={{ marginTop: '1rem' }}>
            처리중 오류가 발생하였습니다.
            <br />
            관리자에게 문의하여 주십시오.
          </div>
          {errCode && (
            <div style={{ color: '#47a0e3', marginTop: '1rem' }}>
              Code: {errCode}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
