import type { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { getCookie, removeCookie } from '../lib/cookie';
import { ERROR_CODE } from './statusCode';
import { decrypt } from '../lib/utils/crypto';
import { setTokensInCookies } from '../lib/utils/setTokensInCookies';
import { TCustomAxiosRequestConfig, TErrorResponseData } from '../types/axios';
import { getNewRefreshToken } from './auth/getNewRefreshToken';

/**
 * 인터셉터에 사용될 함수들을 정의
 * 요청 전에 토큰을 설정하고, 응답 에러를 처리하는 로직
 **/

// 요청 시 토큰을 설정하는 함수
export const checkAndSetToken = async (config: TCustomAxiosRequestConfig) => {
  if (!config.useAuth || config.headers?.Authorization) return config;

  let accessToken: string | null = null;
  accessToken = decrypt(getCookie({ name: 'accessToken' }));

  // 액세스 토큰이 만료된 경우 새로 고침
  if (!accessToken) {
    const newTokens = await getNewRefreshToken();
    accessToken = newTokens.accessToken;
    // 쿠키에 새 토큰 저장
    setTokensInCookies(newTokens);
  }

  // 쿠키에서 엑세스 토큰 가져오기 -> 복호화 작업 필요
  const encryptedAccessToken = getCookie({ name: 'accessToken' });
  if (encryptedAccessToken) {
    accessToken = decrypt(encryptedAccessToken);
  }

  // 요청 헤더에 액세스 토큰 첨부 (인증 토큰 설정)
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

// 리프레시 토큰이 유효하지 않을 때의 처리 함수
const handleInvalidRefreshToken = () => {
  removeCookie({ name: 'refreshToken', options: { path: '/' } });
  removeCookie({ name: 'accessToken', options: { path: '/' } });

  window.location.replace('/');
};

// 엑세스 토큰이 유효하지 않을 때의 처리 함수
const handleInvalidAccessToken = async (originalRequest: AxiosRequestConfig) => {
  try {
    const data = await getNewRefreshToken();

    // 'originalRequest.headers'가 존재할 때에만 값을 할당
    if (originalRequest.headers) {
      originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
    }

    setTokensInCookies({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken
    });

    return await axios(originalRequest);
  } catch (error) {
    throw new Error('토큰을 갱신하는 동안 에러가 발생했습니다.');
  }
};
// 에러를 처리하는 함수
export const handleError = async (error: AxiosError<TErrorResponseData>) => {
  const originalRequest = error.config;

  if (!error.response || !originalRequest) {
    throw new Error('에러가 발생했습니다. : ', error);
  }

  const { data, status } = error.response;

  switch (status) {
    case ERROR_CODE.invalidEmail:
      if (data.code === ERROR_CODE.invalidEmail) {
        throw new Error('잘못된 이메일 에러가 발생했습니다.');
      }
      break;
    case ERROR_CODE.invalidAccessToken:
      return handleInvalidAccessToken(originalRequest);
    case ERROR_CODE.invalidRefreshToken:
      handleInvalidRefreshToken();
      break;
    default:
      throw new Error('알 수 없는 에러가 발생했습니다.');
  }
};
