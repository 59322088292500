import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Typography, Alert, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { TLoginData } from '../../types/api';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../contexts/AuthContext';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
const { Title } = Typography;

export default function Login() {
  const [postState, setPostState] = useState<boolean>(false);
  const [isRemember, setIsRemember] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['rememberUserEmail']);
  const { login } = useAuth();

  const onFinished = async (data: TLoginData) => {
    const username = data.email;
    const password = data.password;
    // const password = encrypt(data.password); // 비밀번호 암호화

    // "이메일 기억하기"가 체크된 경우, 입력한 이메일을 쿠키에 저장 (2,000초 = 약 33분)
    if (isRemember) setCookie('rememberUserEmail', username, { maxAge: 2000000 });
    // 입력된 사용자 이름과 비밀번호를 사용하여 로그인함수를 호출
    if (username && password) {
      try {
        await login(username, password);
      } catch (error) {
        console.error('로그인 실패: ', error);
        // 로그인 오류날 경우, 에러모달을 띄움
        setPostState(true);
      }
    }
  };

  // 이메일 기억하기
  const handleEmailRemember = (e: CheckboxChangeEvent) => {
    setIsRemember(e.target.checked);
    if (!e.target.checked) removeCookie('rememberUserEmail');
  };

  return (
    <div className='login-bg' style={{ backgroundImage: `url('/images/login_bg.jpg') ` }}>
      <div className='login-container'>
        <section className='login-section'>
          <Title level={3} style={{ width: '100%', height: '140px', border: '1px solid red', paddingTop: '50px' }}>
            로그인 (여기 로고들어가는듯)
          </Title>
          <Form
            name='normal_login'
            className='login-form'
            initialValues={{
              //초기값 설정
              remember: cookies.rememberUserEmail ? true : false,
              email: cookies.rememberUserEmail !== undefined ? cookies.rememberUserEmail : ''
            }}
            onFinish={onFinished}
          >
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: '이메일을 입력하세요.'
                }
                // {
                //   pattern: emailPattern,
                //   message: '이메일 형식에 맞지 않습니다.',
                // },
              ]}
            >
              <Input className='login-wid-heig' prefix={<UserOutlined />} placeholder='ID (E-mail)' id='loginEmail' />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: '비밀번호를 입력하세요.'
                }
              ]}
            >
              <Input className='login-wid-heig' prefix={<LockOutlined />} type='password' placeholder='Password' id='loginPassword' />
            </Form.Item>
            <Form.Item name='remember' valuePropName='checked' noStyle>
              <Checkbox onChange={handleEmailRemember}>이메일 기억하기</Checkbox>
            </Form.Item>

            <Form.Item>
              {postState && (
                <Space direction='vertical' style={{ width: '100%', marginTop: '20px' }} className='auth_error'>
                  <Alert message='Error' description='로그인 정보가 맞지 않습니다.' type='error' showIcon />
                </Space>
              )}
              <Button className='login-btn' type='primary' htmlType='submit' block>
                LOGIN
              </Button>
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to='/signup'>계정 생성 하기</Link>
              <Link to='/forget-password'>비밀번호 찾기</Link>
            </div>
          </Form>
        </section>
      </div>
    </div>
  );
}
