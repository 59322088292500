import { ColDef, GetMainMenuItemsParams, GridOptions, IHeaderParams, MenuItemDef, ValueFormatterParams } from 'ag-grid-community';
import { CURRENCY_SYMBOLS } from 'constants/constants';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useMemo } from 'react';
import './commonFunction.css';

// 숫자를 통화 포맷으로 변환하는 함수
export function currencyFormatter(params: ValueFormatterParams, paramsCurrency: string, paramsShowCurrency: boolean) {
  // 데이터가 없거나 null인 경우 ''를 반환
  if (params.value === undefined || params.value === null || isNaN(params.value)) {
    return '';
  } else {
    const currency = paramsCurrency || CURRENCY_SYMBOLS.KRW; // 기본 통화 기호 설정
    // 숫자를 포맷팅할 때 사용할 정규식을 적용하여 숫자를 통화 포맷으로 변환
    const formattedValue = params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return paramsShowCurrency ? `${currency}${formattedValue}` : formattedValue;
  }
}

// 날짜를 특정 형식으로 포맷팅하는 함수
export function dateFormatter(dateValue: string | Date): string {
  console.log('dateFormatter', dateValue);
  const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue; // 입력값이 문자열인 경우 Date 객체로 변환
  // 유효한 날짜인지 확인 - Date 객체가 유효한 경우에만 포맷팅을 수행
  if (isNaN(date.getTime())) return ''; // 유효하지 않은 날짜인 경우 빈 문자열 반환
  return date.toLocaleDateString('ko-KR');
}

// 전화번호를 특정 형식으로 포맷팅하는 함수
export function phoneNumberFormatter(phoneNumber: string): string | null {
  // 공백 제거 및 숫자만 추출
  const cleaned = phoneNumber.replace(/\D/g, '');
  // 유효성 검사: 10자리 또는 11자리 숫자여야 함
  if (!/^\d{10,11}$/.test(cleaned)) return null; // 유효하지 않은 전화번호
  // 포맷팅: 010-1234-5678 형식으로 변환
  const formatted = cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

  return formatted;
}
// 이메일 특정 형식으로 포맷팅하는 함수
export function emailFormatter(value: string) {
  // 이메일 형식을 확인하는 정규표현식
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // 이메일 형식이 맞는지 검사
  const isValidEmail = emailRegex.test(value);
  // 잘못된 형식의 이메일이면 그냥 텍스트로 출력 (TODO: 추후 어떻게 처리해야할지 논의 필요!!!!!!!)
  return isValidEmail ? emailRegex : value;
}
// 여기서 편집기능, 필터기능, 리사이징, 소팅기능 관리할수있음
export const defaultColDef = {
  flex: 1,
  filter: true,
  resizable: true,
  sortable: true,
  editable: true
};

//defaultGridOptions으로 페이지 처리를 삽입
export const defaultGridOptions: GridOptions = {
  //** 지우면 안되는 옵션들 (테스트 한것들) **/
  // cell 너비에 맞춰서 columns사이즈 (필요함!!!!)
  autoSizeStrategy: {
    type: 'fitCellContents'
  },
  pagination: true, // 페이징 여부
  paginationPageSize: 20, // 한페이지 내에서 몇개보여줄것인지
  suppressHorizontalScroll: true, // 수평 스크롤 억제
  //enableFillHandle 활성화하고 enableRangeSelection을 활성화하는 경우 enableFillHandle이 우선합니다.
  // 드래깅 후 복사 붙여넣기하는 기능
  enableRangeSelection: true,
  enableFillHandle: true,
  rowSelection: 'multiple' // rowSelection을 multiple로 설정 : AG Grid: headerCheckboxSelectionFilteredOnly is only supported with rowSelection=multiple에러가 나는 현상 발생
};

export const createGridOptions = (options: { dateFilter?: boolean; excelExport?: boolean }): GridOptions => {
  // defaultGridOptions를 복사하여 gridOptions 생성
  const gridOptions: GridOptions = { ...defaultGridOptions };

  // columnDefs가 undefined인 경우 빈 배열로 초기화
  if (!gridOptions.columnDefs) {
    gridOptions.columnDefs = [];
  }

  // dateFilter 옵션이 true인 경우 columnDefs에 date 필터 추가
  if (options.dateFilter) {
    gridOptions.columnDefs.push({
      field: 'date',
      filter: 'agDateColumnFilter',
      filterParams: {
        // 날짜 필터 추가 매개 변수 전달해야함
      }
    });
  }

  if (options.excelExport) {
    gridOptions.defaultExcelExportParams = {
      exportAsExcelTable: {
        name: 'Test export',
        showRowStripes: false,
        showColumnStripes: true,
        showFilterButton: true
      }
    };
  }

  return gridOptions;
};

interface ColumnDef extends ColDef {
  id: number;
  headerName: string;
  field: string;
  headerCheckboxSelection?: boolean;
  headerCheckboxSelectionFilteredOnly?: boolean;
  checkboxSelection?: boolean;
}
export const components = (params: any) => {
  return {
    agColumnHeader: CustomHeader
  };
};

export function idCheckboxColumnDefs(data: any[]): ColDef[] {
  // 첫 번째 항목의 키들을 가져옴
  const fields = Object.keys(data[0]);

  // 'id' 필드가 가장 먼저 오도록 정렬
  fields.sort((a, b) => (a === 'id' ? -1 : b === 'id' ? 1 : 0));
  // 정렬된 필드들을 기반으로 columnDefs를 생성
  return fields.map((field, index) => {
    const columnDef: ColumnDef = {
      id: index + 1,
      headerName: field,
      field: field,
      headerClass: (params) => {
        return 'required-header';
        // return params ? 'required-header' : ''; // 추후에 require 받아서 선택적 전달해야함
      },
      headerComponentParams: { required: true } // 필요에 따라 required 값을 전달 => 이거는 작동안함..레퍼런스도 없어서 일단 냄겨두고 이후 삭제처리해야할거같음
    };

    if (field === 'id') {
      Object.assign(columnDef, {
        editable: false
      });
    }

    // 각 열에 커스텀 메뉴 항목 추가
    Object.assign(columnDef, {
      mainMenuItems: (params: GetMainMenuItemsParams) => {
        const athleteMenuItems: (MenuItemDef | string)[] = params.defaultItems.slice(0);

        athleteMenuItems.push('separator'); // 구분선 추가

        athleteMenuItems.push({
          name: '선택한 열 전체 복사',
          action: () => {
            const clickColumn = params.api.getColumns();
            const clickColumnName: string = params.column.getColId();
            console.log('선택한 열 전체 복사 - 클릭한 곳 clickColumn : ' + clickColumn);
            console.log('선택한 열 전체 복사 - 클릭한 곳 clickColumnName : ' + clickColumnName);

            const getAllColumnDefs = params.api.getColumnDefs();
            console.log('선택한 열 전체 복사 - getColumnDefs : ', getAllColumnDefs);
            // 클릭한 열에 해당하는 모든 셀의 데이터 가져오기
            const rowData: Record<string, any>[] = [];

            params.api.forEachNodeAfterFilterAndSort((node) => {
              const cellData = node.data[clickColumnName];
              rowData.push({ [clickColumnName]: cellData });
            });

            console.log('선택한 열 전체 복사 - 클릭한 열의 데이터 : ', rowData);
            // 클립보드에 데이터 복사하기 (CSV 형식으로 변환)
            const csvData = rowData.map((obj) => Object.values(obj).join(',')).join('\n');
            navigator.clipboard
              .writeText(csvData)
              .then(() => {
                console.log('클립보드에 데이터 복사 완료');
                // ToDo : 나중에 alert하는거 모달 띄워야할듯
              })
              .catch((err) => {
                console.error('클립보드 복사 실패:', err);
                // ToDo : 나중에 alert하는거 모달 띄워야할듯
              });
          }
        });

        return athleteMenuItems;
      }
    });
    return columnDef;
  });
}
interface CustomHeaderProps extends IHeaderParams {
  required?: boolean;
}

// Custom Header Component
export const CustomHeader = (props: CustomHeaderProps) => {
  const { displayName, required } = props;
  console.log('Custom Header', props, displayName, required);

  return (
    <div className='custom-header'>
      {displayName}
      {required ? <span className='required'>*</span> : null}
    </div>
  );
};
