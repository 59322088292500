import React from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Radio, Select, Space, DatePicker, Input, Checkbox } from 'antd';
import './customGrid.css';
export default function OrderTypeRegistrationInquiry() {
  const { Option } = Select;
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 }
  };

  const config = {
    rules: [
      {
        type: 'object' as const,
        required: true,
        message: '날짜를 선택해주세요'
      }
    ]
  };

  const onFinish = (fieldsValue: any) => {
    // Should format date value before submit.
    const values = {
      ...fieldsValue,
      'order-date': fieldsValue['order-date'].format('YYYY-MM-DD'),
      'due-date': fieldsValue['due-date'].format('YYYY-MM-DD')
    };
    console.log('Received values of form: ', values);
  };

  return (
    <Form
      name='validate_other'
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={
        {
          // 초기 데이터 설정할건지 논의 후 넣기
          // 'business-unit': '본사',
          // 'order-classification': '일반수주',
        }
      }
      style={{
        width: '100%'
      }}
    >
      <table className='responsive-table'>
        <tr>
          <th>사업단위</th>
          <td>
            <Form.Item name='business-unit' hasFeedback rules={[{ required: true }]} style={{}}>
              <Select>
                <Option value='head-office'>본사</Option>
                <Option value='branch-office'>지사</Option>
              </Select>
            </Form.Item>
          </td>
          <th>수주일</th>
          <td>
            <Form.Item name='order-date' {...config} hasFeedback style={{}}>
              <DatePicker />
            </Form.Item>
          </td>
          <th>수주번호</th>
          <td>
            <Form.Item name='order-number' hasFeedback rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </td>
          <th>공급사업자번호명</th>
          <td>
            <Form.Item
              name='supplier-number'
              hasFeedback
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select>
                <Option value='domestic-demand'>내수</Option>
                <Option value='external-demand'>외수(?)</Option>
              </Select>
            </Form.Item>
          </td>
        </tr>
        <tr>
          <th>수주구분</th>
          <td>
            <Form.Item
              name='order-classification'
              hasFeedback
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select>
                <Option value='general-orders'>일반수주</Option>
                <Option value='special-orders'>특별(?)수주</Option>
              </Select>
            </Form.Item>
          </td>
          <th>납기일</th>
          <td>
            <Form.Item name='due-date' {...config} hasFeedback style={{ width: '100%' }}>
              <DatePicker />
            </Form.Item>
          </td>
          <th>담당자</th>
          <td>
            <Form.Item
              name='manager-person'
              hasFeedback
              rules={[
                {
                  required: true
                }
              ]}
              style={{ width: '100%' }}
            >
              <Select>
                <Option value='manager'>관리자</Option>
                <Option value='user'>사용자</Option>
              </Select>
            </Form.Item>
          </td>
          <th>부서</th>
          <td>
            <Form.Item
              name='division'
              hasFeedback
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select>
                <Option value='manager'>부서1</Option>
                <Option value='user'>부서2</Option>
              </Select>
            </Form.Item>
          </td>
        </tr>
        <tr>
          <th>거래</th>
          <td>
            <Form.Item
              name='business'
              hasFeedback
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </td>
          <th>거래처번호</th>
          <td>
            <Form.Item
              name='account-number'
              hasFeedback
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </td>
          <th>위탁구분</th>
          <td>
            <Form.Item
              name='consignment-classification'
              hasFeedback
              style={{ height: 'fit-content' }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select>
                <Option value='general'>일반</Option>
                <Option value='special'>특별(?)</Option>
              </Select>
            </Form.Item>
          </td>

          <td colSpan={2}>
            <Form.Item
              name='radio-group'
              hasFeedback
              style={{ width: '100%' }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Checkbox.Group>
                <Checkbox value='excess-credit'>여신초과</Checkbox>
                <Checkbox value='excess-discount'>할인초과</Checkbox>
                <Checkbox value='confirm'>확정</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </td>
        </tr>
        <tr>
          <th>비고</th>
          <td colSpan={3}>
            <Form.Item
              name='비고'
              hasFeedback
              style={{ width: '100%' }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </td>

          <td colSpan={4}>
            <Form.Item>
              <Space>
                <Button>가용재고</Button>
                <Button>여신조회</Button>
              </Space>
            </Form.Item>
          </td>
        </tr>

        <tr>
          <th colSpan={2}>세금계산서유형</th>
          <td colSpan={2}>
            <Form.Item name='tax-invoice-type' hasFeedback>
              <Radio.Group>
                <Radio value='receipt'>영수</Radio>
                <Radio value='demand'>청구</Radio>
              </Radio.Group>
            </Form.Item>
          </td>
          <th>거래</th>
          <td colSpan={3}>
            <Form.Item name='business' rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </td>
        </tr>
      </table>
      {/* 조회 리셋 */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              조회
            </Button>
            <Button htmlType='reset'>초기화</Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
}
