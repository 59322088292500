import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CommonResponse } from '../../types/axios';
import { axiosInstance } from '../axiosConfig';

interface GetCommonResponse<T> {
  code: number;
  data: T;
  message: string;
}

export const httpService = {
  get: async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<GetCommonResponse<T>>> => {
    try {
      const response = await axiosInstance.get<GetCommonResponse<T>>(url, config);
      console.log('Get response : ', response);
      return response;
    } catch (error) {
      console.error('Get request error:', error);
      throw new Error('Get request error');
    }
  },

  post: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<CommonResponse<T>>> => {
    try {
      const response = await axiosInstance.post<CommonResponse<T>>(url, data, config);
      return response;
    } catch (error) {
      const err = error as any; // 에러를 any 타입으로 캐스팅
      console.error('Post request error:', err);
      console.error('Error details:', err.response.data); // 에러 응답 데이터 출력
      console.error('Error status code:', err.response.status); // 에러 상태 코드 출력
      console.error('Error headers:', err.response.headers); // 에러 응답 헤더 출력
      throw new Error('Post request error');
    }
  },

  put: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<CommonResponse<T>>> => {
    try {
      const response = await axiosInstance.put<CommonResponse<T>>(url, data, config);
      return response;
    } catch (error) {
      console.error('Put request error:', error);
      throw new Error('Put request error');
    }
  },

  patch: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<CommonResponse<T>>> => {
    try {
      const response = await axiosInstance.patch<CommonResponse<T>>(url, data, config);
      return response;
    } catch (error) {
      console.error('Patch request error:', error);
      throw new Error('Patch request error');
    }
  },

  delete: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<CommonResponse<T>>> => {
    try {
      const response = await axiosInstance.delete<CommonResponse<T>>(url, config);
      return response;
    } catch (error) {
      console.error('Delete request error:', error);
      throw new Error('Delete request error');
    }
  }
};
