import create from 'zustand';
import { persist } from 'zustand/middleware';
import { TResponseMeData } from '../types/api';

interface UserState {
  user: TResponseMeData | null;
  setUser: (user: TResponseMeData | null) => void;
}

const useUserStore = create(
  persist<UserState>(
    (set) => ({
      user: null,
      setUser: (user) => set({ user })
    }),
    {
      name: 'userInfo'
    }
  )
);

export default useUserStore;
