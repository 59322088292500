import { AxiosResponse } from 'axios';
import { END_POINT } from '../end-point';
import { getCookie } from '../../lib/cookie';
import { decrypt } from '../../lib/utils/crypto';
import { TMeResponse, TRequestLoginParams, TResponseLogin, TResponseMeData } from '../../types/api';
import { httpService } from '../util/axiosRequests';
import { CommonResponse } from 'types/axios';
import { axiosInstance } from 'apis/axiosConfig';

const authAPI = {
  tokenRefreshAPI: async (data: any) => {
    console.log('tokenRefreshAPI 호출');
    try {
      console.log('토큰 : tokenRefreshAPI data: ', data);
      const response = await httpService.post<TResponseLogin>(END_POINT.authController.postTokenRefresh, data, { withCredentials: true });
      console.log('토큰 : tokenRefreshAPI res data: ', response);
      return response;
    } catch (error) {
      console.error('tokenRefreshAPI 호출 중 오류 발생:', error);
      throw error;
    }
  },

  // 로그인
  loginAPI: async (data: TRequestLoginParams) => {
    console.log('loginAPI 호출');
    try {
      console.log('로그인 : loginAPI data: ', data);
      const response = await httpService.post<TResponseLogin>(END_POINT.authController.postLogin, data, { withCredentials: true });
      console.log('로그인 : loginAPI res data: ', response);
      return response;
    } catch (error) {
      console.error('loginAPI 호출 중 오류 발생:', error);
      throw error;
    }
  },

  // 내 정보 조회
  meAPI: async () => {
    console.log('meAPI 호출');
    try {
      const accessToken = decrypt(getCookie({ name: 'accessToken' }));

      const response = await axiosInstance.get<TMeResponse>(END_POINT.authController.getMe, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${accessToken}` // headers에 accessToken 첨부
        }
      });
      console.log('meAPI 호출, response : ', response);

      return response.data; // 응답의 데이터 return

      // 응답의 데이터  return
    } catch (error) {
      console.error('meAPI 호출 에러:', error);
      throw error; //에러
    }
  }
};

export const { tokenRefreshAPI, loginAPI, meAPI } = authAPI;
