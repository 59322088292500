import React, { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridReadyEvent } from 'ag-grid-community';

interface DataRow {
  id: number;
  name: string;
  age: number;
  checked: boolean;
}

const rowData: DataRow[] = [
  { id: 1, name: '이름1', age: 1, checked: true },
  { id: 2, name: '이름2', age: 2, checked: false },
  { id: 3, name: '이름3', age: 3, checked: true }
];

export default function DynamicGrid() {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { headerName: 'ID', field: 'id', editable: false },
    {
      headerName: 'Checkbox',
      field: 'checked',
      cellEditor: 'agCheckboxCellEditor',
      editable: true
    },
    { headerName: 'Name', field: 'name', editable: (params) => params.data.checked === true },
    { headerName: 'Age', field: 'age', editable: (params) => params.data.checked === true }
  ]);

  const gridApi = useRef<any>(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api; // GridApi를 초기화
    params.api.sizeColumnsToFit(); // 컬럼을 그리드에 맞게 크기 조정
  };

  const getRowId = (data: any): string => {
    return data.id; // DataRow의 id를 문자열로 반환
  };

  return (
    <div className='ag-theme-alpine' style={{ height: '400px', width: '600px' }}>
      <AgGridReact columnDefs={columnDefs} rowData={rowData} onGridReady={onGridReady} getRowId={getRowId} />
    </div>
  );
}
