import { Form, Input, Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { postUsersAPI } from 'apis/user/userAPI';
import { TPostUsersData, TSignUpFormData } from 'types/api';

// 지금은 email이랑 username이 동일하게 넣고 이후에 API 하나로 통일할 예정

export default function SignUp() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phonePattern = /^[0-9]{1,11}$/;

  const onFinished = async (data: TSignUpFormData) => {
    try {
      const { confirmPassword, ...dataWithoutconfirmPassword } = data;
      // username이랑 email을 같은 정보로 넣고, postUsersAPI 호출
      const newData: TPostUsersData = {
        ...dataWithoutconfirmPassword, // confirmPassword를 제외한 나머지 필드를 그대로 복사
        username: data.email // email 값을 username으로 복사
      };
      const response = await postUsersAPI(newData);
      if (response) {
        Modal.success({
          title: '계정이 생성 되었습니다!.',
          content: '생성한 계정으로 로그인 바랍니다.'
        });
        // 폼 값 초기화
        form.resetFields();
        // 로그인 페이지로 이동
        navigate('/login');
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      Modal.error({
        title: '오류가 발생했습니다.',
        content: '계정 신청 중 오류가 발생했습니다. 다시 시도해주세요.'
      });
    }
  };

  return (
    <>
      <button onClick={() => navigate(-1)} style={{ border: 'none', margin: '40px' }}>
        <span className='material-symbols-outlined' style={{ fontSize: '2rem' }}>
          arrow_back
        </span>
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0px auto', width: '60%', minWidth: '300px', maxWidth: '550px' }}>
        {/* 제목 */}
        <h1 style={{ fontSize: '32px', fontWeight: 'bold', lineHeight: '35px', textAlign: 'center', padding: '20px' }}>계정 생성</h1>

        <Form name='account-application' className='account-application-form' form={form} onFinish={onFinished} scrollToFirstError>
          {/* 이름 */}
          <h2 className='title'>
            이름 <span className='requiredMark'>*</span>
          </h2>
          <Form.Item name='name' rules={[{ required: true, message: '이름을 입력하세요.' }]}>
            <Input id='managerInput' prefix={<span className='material-symbols-outlined'>person</span>} placeholder='이름을 입력해주세요.' />
          </Form.Item>
          {/* 이메일 */}
          <h2 className='title'>
            이메일(ID) <span className='requiredMark'>*</span>
          </h2>
          <Form.Item
            name='email'
            rules={[
              { required: true, message: '이메일을 입력하세요.' },
              { pattern: emailPattern, message: '아이디를 이메일 형태로 입력해주세요.' }
            ]}
          >
            <Input id='emailInput' prefix={<span className='material-symbols-outlined'>alternate_email</span>} placeholder='이메일을 입력해주세요.' />
          </Form.Item>
          {/* 비밀번호 */}
          <h2 className='title'>
            비밀번호 <span className='requiredMark'>*</span>
          </h2>

          <Form.Item
            name='password'
            // label='Password'
            rules={[
              {
                required: true,
                message: '비밀번호를 입력하세요.'
              }
            ]}
            hasFeedback
          >
            <Input.Password prefix={<span className='material-symbols-outlined'>lock</span>} />
          </Form.Item>

          {/* 비밀번호 확인 */}
          <h2 className='title'>
            비밀번호 확인 <span className='requiredMark'>*</span>
          </h2>
          <Form.Item
            name='confirmPassword'
            // label='Confirm Password'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: '비밀번호 확인을 입력하세요.'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                }
              })
            ]}
          >
            <Input.Password prefix={<span className='material-symbols-outlined'>lock</span>} />
          </Form.Item>

          {/* 연락처 */}
          <h2 className='title'>
            연락처 <span className='requiredMark'>*</span>
          </h2>
          <Form.Item
            name='phoneNumber'
            rules={[
              { required: true, message: '연락처를 입력하세요.' },
              { pattern: phonePattern, message: '연락처 형식에 맞지 않습니다.' }
            ]}
          >
            <Input id='telNumberInput' prefix={<span className='material-symbols-outlined'>phone_iphone</span>} placeholder='연락처를 입력해주세요.' />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type='primary'
              htmlType='submit'
              className='submit-btn'
              block
              id='submitBtn'
              style={{
                width: '300px',
                height: '50px',
                backgroundColor: '#5182FF',
                fontSize: '24px',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '30px',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'
              }}
            >
              신청하기
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
