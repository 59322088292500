import React from 'react';
import { Avatar, Dropdown, Space } from 'antd';
import useUserStore from 'store/useUserStore';
import { useAuth } from 'contexts/AuthContext';
import type { MenuProps } from 'antd';
import './menu.css';

export default function UserMenu() {
  const { user } = useUserStore();
  const userName = user ? user.name : '로그인풀림?';
  const { isLoggedIn, logout } = useAuth();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target='_blank' rel='noopener noreferrer' href='https://www.naver.com'>
          계정정보
        </a>
      )
    },
    {
      key: '2',
      label: <div onClick={isLoggedIn ? logout : undefined}>로그아웃</div>
    }
  ];

  return (
    <Dropdown menu={{ items }}>
      <button id='avatar-container' onClick={(e) => e.preventDefault()}>
        <Space>
          {/* ToDo : 아바타 src API 정보 넣기 */}
          <Avatar src='https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg' size='large' />
          <span id='user-name' style={{ color: 'white' }}>
            {userName}
          </span>
        </Space>
      </button>
    </Dropdown>
  );
}
