import React from 'react';
import { Dropdown, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import './menu.css';

export default function BottomThreeMenu() {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target='_blank' rel='noopener noreferrer' href='https://www.naver.com'>
          사용안내
        </a>
      )
    },
    {
      key: '2',
      label: (
        <a target='_blank' rel='noopener noreferrer' href='https://www.naver.com'>
          고객지원
        </a>
      )
    },
    {
      key: '3',
      label: (
        <a target='_blank' rel='noopener noreferrer' href='https://www.naver.com'>
          iERP
        </a>
      )
    }
  ];

  return (
    <Dropdown menu={{ items }}>
      <button id='question-container' onClick={(e) => e.preventDefault()}>
        <Space>
          <QuestionCircleOutlined
            style={{
              fontSize: '1.5rem',
              color: 'white',
              textAlign: 'center'
            }}
          />
        </Space>
      </button>
    </Dropdown>
  );
}
