import React, { useState, useCallback, useMemo, useRef } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GetContextMenuItemsParams, MenuItemDef, ModuleRegistry } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';

function createFlagImg(flag: string) {
  return '<img border="0" width="15" height="10" src="https://flags.fmcdn.net/data/flags/mini/' + flag + '.png"/>';
}
interface IOlympicData {
  athlete: string;
  age: number;
  country: string;
  year: number;
  date: string;
  sport: string;
  gold: number;
  silver: number;
  bronze: number;
  total: number;
}
export default function ContextMenu() {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: 350, width: '100%' }), []);
  const [rowData, setRowData] = useState<IOlympicData[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: 'athlete', minWidth: 200 },
    { field: 'age' },
    { field: 'country', minWidth: 200 },
    { field: 'year' },
    { field: 'date', minWidth: 180 },
    { field: 'sport', minWidth: 200 },
    { field: 'gold' },
    { field: 'silver' },
    { field: 'bronze' },
    { field: 'total' }
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const onGridReady = useCallback((params: any) => {
    fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
      .then((resp) => resp.json())
      .then((data: IOlympicData[]) => setRowData(data));
  }, []);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const result: (string | MenuItemDef)[] = [
        'cut',
        'copy',
        'paste',
        'resetColumns',
        'separator',
        'export',
        'csvExport',
        'excelExport',
        'chartRange',
        'pivotChart',
        'autoSizeAll',
        'expandAll',
        'contractAll',
        {
          // custom item
          name: 'Alert ' + params.value,
          action: () => {
            window.alert('Alerting about ' + params.value);
          },
          cssClasses: ['red', 'bold']
        },
        {
          // custom item
          name: 'Always Disabled',
          disabled: true,
          tooltip: ''
        },
        {
          name: 'Country',
          subMenu: [
            {
              name: 'Ireland',
              action: () => {
                console.log('Ireland was pressed');
              },
              icon: createFlagImg('ie')
            },
            {
              name: 'UK',
              action: () => {
                console.log('UK was pressed');
              },
              icon: createFlagImg('gb')
            },
            {
              name: 'France',
              action: () => {
                console.log('France was pressed');
              },
              icon: createFlagImg('fr')
            }
          ]
        },
        {
          name: 'Person',
          subMenu: [
            {
              name: 'Niall',
              action: () => {
                console.log('Niall was pressed');
              }
            },
            {
              name: 'Sean',
              action: () => {
                console.log('Sean was pressed');
              }
            },
            {
              name: 'John',
              action: () => {
                console.log('John was pressed');
              }
            },
            {
              name: 'Alberto',
              action: () => {
                console.log('Alberto was pressed');
              }
            },
            {
              name: 'Tony',
              action: () => {
                console.log('Tony was pressed');
              }
            },
            {
              name: 'Andrew',
              action: () => {
                console.log('Andrew was pressed');
              }
            },
            {
              name: 'Kev',
              action: () => {
                console.log('Kev was pressed');
              }
            },
            {
              name: 'Will',
              action: () => {
                console.log('Will was pressed');
              }
            },
            {
              name: 'Armaan',
              action: () => {
                console.log('Armaan was pressed');
              }
            }
          ]
        }, // built in separator
        'separator',
        {
          // custom item
          name: 'Windows',
          shortcut: 'Alt + W',
          action: () => {
            console.log('Windows Item Selected');
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />'
        },
        {
          // custom item
          name: 'Mac',
          shortcut: 'Alt + M',
          action: () => {
            console.log('Mac Item Selected');
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>'
        }, // built in separator
        'separator',
        {
          // custom item
          name: 'Checked',
          checked: true,
          action: () => {
            console.log('Checked Selected');
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>'
        } // built in copy item
      ];
      return result;
    },
    [window]
  );

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={'ag-theme-quartz'}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection={true}
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}
