import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import AuthProvider from './contexts/AuthContext';
import koKR from 'antd/lib/locale/ko_KR';
//** AG Grid의 CSS 파일을 프로젝트에 추가 **/
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// 라이센스 키
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
const secretKey = process.env.REACT_APP_AG_GRID_LICENSE_KEY;
console.log('secretKey Type: ' + typeof secretKey, 'secretKey: ' + secretKey);
if (typeof secretKey === 'undefined') {
  throw new Error('AG Grid license key is not defined in environment variables.');
}
LicenseManager.setLicenseKey(secretKey);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CookiesProvider>
            <ConfigProvider
              locale={koKR} //antd 한국어 설정
              theme={{
                components: {
                  Tabs: {
                    /* 상단 탭 커스텀 하는 방법 */
                    itemSelectedColor: 'white', // 상단탭 : 선택했을때 폰트 색상
                    itemColor: 'black' // 상단탭 : 기본적 폰트 색상
                  }
                }
              }}
            >
              <App />
            </ConfigProvider>
          </CookiesProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>
);
reportWebVitals();
