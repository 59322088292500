import React from 'react';
import { Alert, Flex, Spin } from 'antd';

export default function Loading() {
  const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };

  const content = <div style={contentStyle} />;

  return (
    <div>
      <Spin tip='Loading...'>{content}</Spin>
    </div>
  );
}
