import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { PageContainer, ProCard, ProLayout } from '@ant-design/pro-components';

import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import DraggableTabNode from './DraggableTabNode';
import { TabType } from '../../types/layout';
import SearchMenuModal from '../SearchMenuModal';
import BottomThreeMenu from '../Menu/BottomThreeMenu';
import NoticeModal from 'components/Modal/NoticeModal';
import ToolBar from './ToolBar';
import UserMenu from 'components/Menu/UserMenu';
import { Flex, Space, Tabs } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import type { TabsProps } from 'antd';
import './commonLayout.css';
import useUserStore from 'store/useUserStore';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import { StarFilled, StarOutlined } from '@ant-design/icons';

const CommonLayout = () => {
  const navigate = useNavigate();
  const [pathname, setPathname] = useState('/');
  const [tabArray, setTabArray] = useState<TabType[]>([
    {
      key: 'main',
      label: '메인',
      children: <Outlet />,
      closable: false,
      path: '/'
    }
  ]);
  const [activeKey, setActiveKey] = useState<string>(tabArray[0].key);


  useEffect(() => {
    console.log('tabArray changed:', tabArray);
  }, [tabArray]);

  const onChangeTab = useCallback(
    (key: string) => {
      // tab을 누르면, 해당하는 페이지로 이동해야해서 navigate 사용
      const selectedTab = tabArray.find((tab) => tab.key === key);
      if (selectedTab?.path) {
        navigate(selectedTab.path);
      }
      setActiveKey(key);
    },
    [tabArray, navigate]
  );

  const onEditTab: TabsProps['onEdit'] = (targetKey, action) => {
    if (action === 'remove') {
      removeTab(targetKey as string);
    }
  };

  const addTab = useCallback(
    (newTitle: string, newPath: string) => {
      // tabArray에 newKey가 이미 있는지 확인
      const keyExists = tabArray.some((tab) => tab.key === newTitle);
      // 존재하면 이미 있는 곳으로 포커싱
      if (keyExists) {
        setActiveKey(newTitle);
        return;
      }
      const newPanes = [...tabArray, { key: newTitle, label: newTitle, children: <Outlet />, path: newPath }];
      setTabArray(newPanes);
      setActiveKey(newTitle);
    },
    [tabArray]
  );

  const removeTab = useCallback(
    (targetKey: string) => {
      let newActiveKey = activeKey;
      let lastIndex = -1;
      tabArray.forEach((tab, i) => {
        if (tab.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const newPanes = tabArray.filter((tab) => tab.key !== targetKey);
      if (newPanes.length && newActiveKey === targetKey) {
        if (lastIndex >= 0) {
          newActiveKey = newPanes[lastIndex].key;
        } else {
          newActiveKey = newPanes[0].key;
        }
      }
      setTabArray(newPanes);
      setActiveKey(newActiveKey);
    },
    [activeKey, tabArray]
  );

  // 드래그관련
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 }
  });

  // 메뉴 북마크 버튼
  const handleBookmarkBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const bookmarkButton = e.currentTarget as HTMLButtonElement;
    // 현재 요소의 부모 노드를 통해 형제 노드에 접근
    const siblingElement = bookmarkButton.nextSibling;
    console.log('즐겨찾기 클릭', siblingElement);
    // 이후 API연결
  };
  // path와 label을 받아서 체크박스와 링크를 렌더링하는 함수
  const renderCheckboxLink = (path: string, label: string): JSX.Element => (
    <>
      {/* ToDo : isChecked 상태관리하는 메뉴 만들어야함 (백엔드 데이터로 오는지 확인) */}
      {/* <Checkbox {...label} icon={isChecked ? <StarFilled /> : <StarOutlined />} checkedIcon={<StarFilled />} /> */}
      <Checkbox onClick={handleBookmarkBtn} icon={<StarOutlined />} checkedIcon={<StarFilled />} />
      <Link to={path}>{label}</Link>
    </>
  );
  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTabArray((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  }, []);
  const { user } = useUserStore();
  const menus = {
    // location: {
    //   pathname: '/'
    // },
    route: {
      path: '/',
      routes: user?.menus.map((menu) => {
        return {
          // path: menu.url ? menu.url : '/',
          // url이 없으면 임의 키를 만들어주던
          path: menu.url ? menu.url : menu.label,
          name: menu.label,
          icon: menu.icon,
          routes: menu.children?.map((subMenu: any) => {
            return {
              path: subMenu.url,
              name: renderCheckboxLink(subMenu.url, subMenu.label),
              icon: subMenu.icon
            };
          })
        };
      })
    }
  };
  console.log('menus', menus);

  return (
    <div
    // style={{
    //   height: '100vh'
    // }}
    >
      <ProLayout
        siderWidth={280} //메뉴 너비 설정
        {...menus} //메뉴 props
        location={{
          pathname
        }}
        // 타이틀 및 로고 : 메뉴 닫히면 사라짐
        title=''
        logo={
          <img
            id='logo-header'
            src='/images/logo.png'
            alt='logo'
            width={170}
            onClick={() => {
              navigate('/');
              onChangeTab('main');
            }}
          />
        }
        // 프로필 관련 props
        avatarProps={{
          render() {
            return <UserMenu key='UserMenu' />;
          }
        }}
        // 프로필 옆에 부가적인 props
        actionsRender={() => {
          return [
            // 메뉴찾기 돋보기 아이콘
            <SearchMenuModal key='SearchMenuModal' />,
            // 3개 메뉴 열리는 물음표 아이콘
            <BottomThreeMenu key='BottomThreeMenu' />
          ];
        }}
        menuItemRender={(item, dom) => (
          <div
            id='menu-three-deps-item'
            role='button'
            tabIndex={0}
            onClick={(e) => {
              const menuName = String((e.target as HTMLElement).textContent);
              const path = String(item.path);
              // ToDo : 현재 path랑 manuName이 같으면 클릭해도 리턴하는 로직 있으면 좋을듯?
              console.log('클릭한곳 item : ', item, menuName);
              // addTab함수 (클릭하면 Tab에 add됨)
              menuName && addTab(menuName, path);
            }}
          >
            {dom}
          </div>
        )}
        // 메뉴 아래 위 마크 색상 설정하는것
        token={{
          sider: {
            // colorTextMenu: 'white', //메누 폰트 색상 : 이거 적용하면 collapsed되었을때 커스텀이 적용안되는 현상으로 주석
            colorTextCollapsedButton: 'green' // 메뉴 collapsed 닫히는 아이콘 버튼
          }
        }}
      >
        {/* 오른쪽에 있는 컴포넌트 렌더링 관련 */}
        <PageContainer>
          <ProCard
            style={{
              height: '100%',
              minHeight: 1000,
              border: '1px solid red'
            }}
          >
            <Tabs
              type='editable-card'
              onEdit={onEditTab}
              onChange={onChangeTab}
              activeKey={activeKey}
              renderTabBar={(tabBarProps, DefaultTabBar) => (
                <Flex style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                    <SortableContext items={tabArray.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
                      <DefaultTabBar {...tabBarProps} style={{ width: '95%', justifyContent: 'flex-end' }}>
                        {(node) => (
                          <DraggableTabNode {...node.props} key={node.key}>
                            {node}
                          </DraggableTabNode>
                        )}
                      </DefaultTabBar>
                    </SortableContext>
                  </DndContext>
                  {/* Notice 아이콘 */}
                  <NoticeModal />
                </Flex>
              )}
            >
              {tabArray.map((tab) => (
                <Tabs.TabPane tab={tab.label} key={tab.key} closable={tab.closable}>
                  <ToolBar />
                  <div>
                    (나중에 지워야함) title(=label): {tab.label} / key: {tab.key}
                  </div>
                  {tab.children}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </ProCard>
        </PageContainer>
      </ProLayout>
    </div>
  );
};
export default React.memo(CommonLayout);
