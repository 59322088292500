import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import './authLayoput.css';

const AuthLayout = () => {
  return (
    <Layout className='auth-layout'>
      <Outlet />
    </Layout>
  );
};
export default AuthLayout;
