import { END_POINT } from '../end-point';
import { axiosInstance } from '../axiosConfig';
import { getCookie } from '../../lib/cookie';
import { decrypt } from '../../lib/utils/crypto';
import { TGetNewRefreshTokenProps, TResponse } from '../../types/token';

// 새로운 refreshToken을 가져오는 함수
export const getNewRefreshToken = async () => {
  try {
    // 쿠키에서 'refreshToken'을 가져와서 복호화
    const refreshToken = decrypt(getCookie({ name: 'refreshToken' }));

    // refreshToken이 존재하지 않으면 에러를 발생시킴
    if (!refreshToken) {
      throw new Error('Refresh token is not available');
    }

    // axiosInstance를 사용하여 새로운 토큰을 요청
    const { data: response } = await axiosInstance.post<TResponse<TGetNewRefreshTokenProps>>(END_POINT.authController.postTokenRefresh, null, {
      // 요청 헤더에 Authorization 헤더를 설정
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    // 서버로부터 받은 응답 데이터를 콘솔에 출력
    console.log('getNewToken===response.data.accessToken : ', response.data);

    // 새로운 토큰 데이터를 반환
    return response.data;
  } catch (error) {
    // 에러가 발생했을 때 에러 메시지를 콘솔에 출력하고 에러를 던짐
    console.error('Error getting new token:', error);
    throw error;
  }
};
