import { END_POINT } from '../end-point';
import { TItemsResponseData, TPostUsersData, TRequestLoginParams, TResponseLogin, TResPostUsers } from '../../types/api';
import { httpService } from '../util/axiosRequests';

const userAPI = {
  // 상품 전체조회

  getItemsAPI: async () => {
    const response = await httpService.get<TItemsResponseData>(END_POINT.itemController.getItems(), {
      withCredentials: true,
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      }
    });
    return response;
  }
};

export const { getItemsAPI } = userAPI;
