// 조회(돋보기버튼 클릭)하면 결과 AG Grid 로 나오는 모달

import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import CustomModal from './CustomModal';
import ItemApiGrid from 'components/AgGrid/ItemApiGrid';

export default function SearchModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  return (
    <>
      <SearchOutlined
        style={{
          fontSize: '1rem',
          color: 'black'
        }}
        onClick={showModal}
      />

      <CustomModal title='조회 결과' isOpen={isModalOpen} onClose={handleCancel} modalWidth={'70vw'}>
        <div>컴포넌트 : SearchModal</div>
        여기는 뭐를 클릭하는지에 따라서 데이터 불러 온 후에 AGGrid 넣어야함 / 지금은 임시로 api Grid를 넣음
        <ItemApiGrid />
      </CustomModal>
    </>
  );
}
