import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CommonLayout from './components/Layouts/CommonLayout';
import AuthLayout from './components/Layouts/AuthLayout';
import { LOGIN_ROUTES, ROUTES } from './constants/url';
import { useAuth } from './contexts/AuthContext';

export default function App() {
  // 로그인 후 로그인페이지 혹은 메인으로 이동하는 로직
  const { isLoggedIn } = useAuth();

  return (
    <div className='App'>
      <Routes>
        {/* 왼쪽 메뉴들이 있는 컴포넌트들 */}
        <Route element={isLoggedIn ? <CommonLayout /> : <Navigate to='/login' replace />}>
          {Object.values(ROUTES).map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* 로그인하는 페이지로, 왼쪽 메뉴가 없는 AuthLayout */}
        <Route element={!isLoggedIn ? <AuthLayout /> : <Navigate to='/' replace />}>
          {Object.values(LOGIN_ROUTES).map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
    </div>
  );
}
