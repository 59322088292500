import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ModuleRegistry, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { currencyFormatter, dateFormatter, defaultColDef } from './CommonFunction';
import {
  CellEditingStartedEvent,
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  GetRowIdParams,
  ICellRendererComp,
  ICellRendererParams,
  MenuModule,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  CellEditingStoppedEvent,
  ValueFormatterParams
} from 'ag-grid-enterprise';
import { getData } from './data';
import { CURRENCY_SYMBOLS } from 'constants/constants';

ModuleRegistry.registerModules([ExcelExportModule]);
function endsWith(str: string | null, match: string | null) {
  //   let len;
  if (str == null || !str.length || match == null || !match.length) {
    return false;
  }
  const len = str.length;
  return str.substring(len - match.length, len) === match;
}
function getFileIcon(name: string) {
  return endsWith(name, '.mp3') || endsWith(name, '.wav')
    ? 'far fa-file-audio'
    : endsWith(name, '.xls')
      ? 'far fa-file-excel'
      : endsWith(name, '.txt')
        ? 'far fa-file'
        : endsWith(name, '.pdf')
          ? 'far fa-file-pdf'
          : 'far fa-folder';
}
function getFileCellRenderer() {
  class FileCellRenderer implements ICellRendererComp {
    eGui: any;
    init(params: ICellRendererParams) {
      const tempDiv = document.createElement('div');
      const value = params.value;
      const icon = getFileIcon(params.value);
      tempDiv.innerHTML = icon ? '<span><i class="' + icon + '"></i>' + '<span class="filename"></span>' + value + '</span>' : value;
      this.eGui = tempDiv.firstChild;
    }
    getGui() {
      return this.eGui;
    }
    refresh() {
      return false;
    }
  }
  return FileCellRenderer;
}
export default function TreeGrid() {
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState<any[]>(getData());
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: 'dateModified',
      minWidth: 250,
      valueFormatter: (params) => dateFormatter(params.value),
      comparator: (d1, d2) => {
        return new Date(d1).getTime() < new Date(d2).getTime() ? -1 : 1;
      }
    },
    {
      field: 'size',
      aggFunc: 'sum',
      valueFormatter: (params) => {
        return params.value ? Math.round(params.value * 10) / 10 + ' MB' : '0 MB';
      }
    },
    {
      field: 'price',
      valueFormatter: (params) => currencyFormatter(params, CURRENCY_SYMBOLS.KRW, true)
    }
  ]);

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Files',
      minWidth: 330,
      cellRendererParams: {
        checkbox: true,
        suppressCount: true,
        innerRenderer: getFileCellRenderer()
      }
    };
  }, []);
  const getDataPath = useCallback((data: any) => {
    return data.filePath;
  }, []);

  const onBtnClearRowData = useCallback(() => {
    setRowData([]);
  }, []);
  return (
    <>
      <button onClick={onBtnClearRowData}>Clear rowData</button>
      <div style={gridStyle} className={'ag-theme-quartz'}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true} // 트리컴포넌트 여부
          groupDefaultExpanded={-1}
          getDataPath={getDataPath}
          domLayout='autoHeight'
          enterNavigatesVertically={true}
          enterNavigatesVerticallyAfterEdit={true}
        />
      </div>
    </>
  );
}
