import CryptoJS from 'crypto-js';
/** CryptoJS를 사용하여 암호화 및 복호화하는 코드 **/

// secretKey를 환경 변수로부터 안전하게 가져옴
const secretKey = process.env.REACT_APP_SECRET_KEY;

// secret key가 정의되지 않은 경우 에러를 발생시킴
if (!secretKey) {
  throw new Error('Secret key is not defined.');
}

// payload를 암호화하는 함수
export const encrypt = (payload: string) => {
  // secret key가 정의되지 않은 경우 null을 반환
  if (!secretKey) return null;
  // payload를 UTF-8 형식으로 인코딩(변환)
  const utf8Payload = CryptoJS.enc.Utf8.parse(payload);
  // AES 알고리즘을 사용하여 UTF-8 형식으로 인코딩된 payload 암호화
  const encrypted = CryptoJS.AES.encrypt(
    utf8Payload,
    secretKey as string
  ).toString();
  // 암호화된 데이터를 반환
  return encrypted;
};

// ciphertext를 복호화하는 함수
export const decrypt = (ciphertext?: string): string | null => {
  // secret key 또는 ciphertext가 정의되지 않은 경우 null을 반환
  if (!secretKey || !ciphertext) return null;

  try {
    // secretKey를 기반으로 복호화
    const decrypted_bytes = CryptoJS.AES.decrypt(
      ciphertext,
      secretKey as string
    );

    // 복호화된 바이트를 UTF-8 형식의 문자열로 디코딩
    const decrypted = decrypted_bytes.toString(CryptoJS.enc.Utf8);
    // 복호화된 데이터를 반환
    return decrypted;
  } catch (error) {
    // 복호화 중에 오류가 발생한 경우 에러를 콘솔에 출력하고 null을 반환
    console.error(`Error decrypting data: ${error}`);
    return null;
  }
};
