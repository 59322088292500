import React, { createContext, useContext, useEffect, useState } from 'react';
import { loginAPI, meAPI } from '../apis/auth/authAPI';
import { removeTokensFromCookies, setTokensInCookies } from '../lib/utils/setTokensInCookies';
import useUserStore from '../store/useUserStore';
import { TAuthContextType, TAuthProviderProps, TMeResponse, TResponseMeData } from '../types/api';

const AuthContext = createContext<TAuthContextType | undefined>(undefined);

export default function AuthProvider({ children }: TAuthProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUser } = useUserStore();

  useEffect(() => {
    // 페이지가 로드될 때 로컬 스토리지에서 로그인 상태를 확인
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      // 로그인 API 호출
      const res = await loginAPI({ email, password });
      const accessToken = res.data.accessToken;
      const refreshToken = res.data.refreshToken;

      // accessToken, refreshToken을 Cookies에 저장 (7일동안 저장하는것을 기본으로 설정해둠)
      setTokensInCookies({ accessToken, refreshToken });

      // API호출하여 유저 정보 가져오기
      const userResponse: TMeResponse = await meAPI();

      // zustand에 사용자 데이터 저장
      const userData: TResponseMeData = userResponse.data;
      console.log('meAPI userResponse - userData : ', userData);
      setUser(userData);

      setIsLoggedIn(true);
      // 로컬 스토리지에 로그인 상태를 저장
      localStorage.setItem('isLoggedIn', 'true');
      //  return userData;
    } catch (error) {
      // 로그인 오류날 경우, 에러모달을 띄움
      console.error('loginAPI - error: ', error);
      throw error;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    // 로그아웃 로직
    // Zustand의 사용자 정보 초기화
    setUser(null);
    // 쿠키에 저장된 accessToken과 refreshToken 삭제
    removeTokensFromCookies();
    // 로컬 스토리지에서 로그인 상태를 삭제합니다.
    localStorage.removeItem('isLoggedIn');
  };

  return <AuthContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AuthContext.Provider>;
}

export const useAuth = (): TAuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
