// 사용자 정보 조회를 위한 URL을 생성하는 함수
const getUserUrl = (base: string, cursor?: number) => {
  const urlSearchParams = new URLSearchParams();
  if (cursor !== undefined) {
    urlSearchParams.append('cursor', String(cursor));
  }
  return `${base}?${urlSearchParams.toString()}`;
};

export const END_POINT = {
  // 인증
  authController: {
    postTokenRefresh: '/token/refresh', // 토큰 갱신을 위한 POST 요청
    postLogin: '/login', // 로그인을 처리하는 엔드포인트
    getMe: '/me' // 내 정보(로그인 한 사용자) 조회
  },
  // 사용자
  userController: {
    main: '/users', // 사용자 엔드포인트의 기본 경로
    /** GET **/
    getUsers: (cursor?: number) => getUserUrl(END_POINT.userController.main, cursor), // 사용자 정보 조회
    getUserMenus: (userId?: string) => `${END_POINT.userController.main}/${userId}/menus`, // 유저 메뉴조회
    /** POST **/
    postUsers: () => `${END_POINT.userController.main}`, // 사용자 회원가입(=사용자 생성)
    /** DELETE **/
    deleteUser: (userId?: string) => `${END_POINT.userController.main}/${userId}` // 사용자 탈퇴(=사용자 삭제)
  },
  // 설정
  configurationController: {
    getConfigurations: (cursor?: number) => getUserUrl('/configurations', cursor) // 시스템 설정 조회
  },
  // 회사
  companyController: {
    main: '/companies', // 회사 엔드포인트의 기본 경로
    /** POST **/
    postCompanies: () => END_POINT.companyController.main, // 회사 생성을 위한 엔드포인트
    postCompaniesName: (companyId: string) => `${END_POINT.companyController.main}/${companyId}/users`, // 회사에 사용자 추가
    /** GET **/
    getCompanies: () => END_POINT.companyController.main, // 회사 전체 조회
    getCompaniesName: (companyId: string) => `${END_POINT.companyController.main}/${companyId}`, // 특정 회사 조회
    getCompaniesNameUsers: (companyId: string) => `${END_POINT.companyController.main}/${companyId}/users`, // 회사의 사용자 목록 조회
    /** DELETE **/
    deleteName: (companyId: string) => `${END_POINT.companyController.main}/${companyId}`, // 회사 삭제 (이름에 따른 회사 삭제)
    deleteUsername: (companyId: string, userId: string) => `${END_POINT.companyController.main}/${companyId}/users/${userId}` // 회사 사용자 삭제 (사용자명에 따른 회사 삭제)
  },
  // 상품
  itemController: {
    main: '/items', // 상품 엔드포인트의 기본 경로
    /** GET **/
    getItems: () => END_POINT.itemController.main // 상품 전체 조회
  }
};
