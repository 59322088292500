import { END_POINT } from '../end-point';
import { TPostUsersData, TRequestLoginParams, TResponseLogin, TResPostUsers } from '../../types/api';
import { httpService } from '../util/axiosRequests';

const userAPI = {
  // 회원가입
  postUsersAPI: async (data: TPostUsersData) => {
    console.log('postUsersAPI 호출');
    try {
      console.log('회원가입 : postUsersAPI data: ', data);
      const response = await httpService.post<TResPostUsers>(END_POINT.userController.postUsers(), data, { withCredentials: true });
      console.log('회원가입 : postUsersAPI res data: ', response);
      return response;
    } catch (error) {
      console.error('회원가입 API 호출 중 오류 발생:', error);
      throw error;
    }
  },

  userMenuAPI: async (username: string) => {
    // console.log('userMenuAPI data:', data);
    const response = await httpService.get<any>(END_POINT.userController.getUserMenus(username), {
      withCredentials: true,
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      }
    });
    return response;
  }
};

export const { postUsersAPI, userMenuAPI } = userAPI;
