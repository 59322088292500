import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { createGridOptions, currencyFormatter, defaultColDef, idCheckboxColumnDefs } from './CommonFunction';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { CURRENCY_SYMBOLS } from 'constants/constants';

export default function CheckboxGrid() {
  // ** 테스트 1 : 목데이터 **//
  const [rowData] = useState<any[]>([
    { id: 1, artist: 'Nirvana', country: 'US', price: 1987, date: '2024/06/12' },
    { id: 2, artist: 'Arctic Monkeys', country: 'UK', price: 2005, date: '2024/06/02' },
    { id: 3, artist: 'R.E.M.', country: 'US', price: 1980, date: '2024/01/12' },
    { id: 4, artist: 'AC/DC', country: 'Australia', price: 1975, date: '2024/03/12' },
    { id: 5, artist: 'Blur', country: 'UK', price: 1990, date: '2024/02/12' },
    { id: 6, artist: 'Tahiti80', country: 'France', price: 1993, date: '2024/07/12' }
  ]);
  const [columnDefs] = useState<ColDef[]>([
    { field: 'id', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, rowGroup: true },
    { field: 'artist', checkboxSelection: true },
    { field: 'country' },
    { field: 'price', valueFormatter: (params) => currencyFormatter(params, CURRENCY_SYMBOLS.KRW, true), aggFunc: 'sum' }
  ]);

  // ** 임의 API 사이트 (test용) **//
  const [rowData1, setRowData1] = useState<any[]>([]);
  const [columnDefs1, setColumnDefs1] = useState<ColDef[]>([]);

  useEffect(() => {
    console.log('변경 : columnDefs1 : ', columnDefs1);
  }, [columnDefs1]);
  const onGridReady = async () => {
    try {
      // 임의 API 사이트 (test용)
      const response = await fetch('https://jsonplaceholder.typicode.com/todos');
      const data = await response.json(); //id가 있는 데이터임
      setRowData1(data);
      // setColumnDefs1 함수에 columnDefs를 전달
      const columnDefs = idCheckboxColumnDefs(data);
      setColumnDefs1(columnDefs);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='ag-theme-alpine' style={{ height: 350, width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={createGridOptions({ dateFilter: true, excelExport: true })}
          enableRangeSelection={true}
          copyHeadersToClipboard={true}
        />
      </div>

      <div className='ag-theme-alpine' style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData1}
          columnDefs={columnDefs1}
          defaultColDef={defaultColDef}
          gridOptions={createGridOptions({ dateFilter: true, excelExport: true })}
          onGridReady={onGridReady}
          domLayout='autoHeight' // 높이 자동
          enableCellTextSelection={false} //셀 내의 텍스트를 선택할 수 있도록 설정 .true=클립보드 비활성화, 선택한 텍스트만 복사
          enterNavigatesVertically={true} // Enter 키를 누르면 아래 셀로 이동
          enterNavigatesVerticallyAfterEdit={true} // Enter 키를 누르면 아래 셀로 이동 (*AfterEdit)
          rowSelection={'multiple'} // id쪽 체크박스 여러개 할수있는 옵션!!!! (*완전 필요함)
          copyHeadersToClipboard={false} // 클립보드로 복사할 때 머리글도 포함 = true
          suppressCopySingleCellRanges={true} // 단일 셀만 있는 범위를 선택할 때 범위 대신 행을 복사하려면 true
          suppressCutToClipboard={false}
          enableRangeSelection={true} // 범위 선택을 활성화
        />
      </div>
    </>
  );
}
