import React, { useState, useCallback, useMemo, useRef } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef, GridApi, ModuleRegistry } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { createGridOptions, dateFormatter, idCheckboxColumnDefs } from './CommonFunction';
import { ExcelExportModule } from 'ag-grid-enterprise';

ModuleRegistry.registerModules([ExcelExportModule]);

interface IOlympicData {
  athlete: string;
  age: number | string;
  country: string;
  year: number;
  date: string;
  sport: string;
  gold: number;
  silver: number;
  bronze: number;
  total: number;
}

export default function MoveGrid() {
  const gridRef = useRef<AgGridReact<IOlympicData>>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState<IOlympicData[]>();
  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      lockPinned: true // Dont allow pinning for this example
    };
  }, []);

  const onGridReady = async () => {
    try {
      // 임의 API 사이트 (test용)
      const response = await fetch('https://www.ag-grid.com/example-assets/olympic-winners.json');
      const data = await response.json();

      // 데이터 정렬
      setRowData(
        data.map((rowData: any) => {
          const dateParts = rowData.date.split('/');
          return {
            ...rowData,
            date: `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}` // 날짜 셋팅
          };
        })
      );
      // 얘는 id칼럼을 넣었는데 id데이터가 없어서 안나타나짐
      const columnDefs = idCheckboxColumnDefs(data);
      // setColumnDefs(columnDefs);
      // setColumnDefs({
      //   headerName: 'Athlete Details',
      //   children: [columnDefs]
      // });
      // // 칼럼 정렬
      setColumnDefs([
        {
          headerName: '멀티Header',
          children: Object.keys(data[0]).map((field) => {
            if (['gold', 'silver', 'bronze', 'total'].includes(field)) {
              return {
                headerName: field,
                field: field,
                aggFunc: 'sum'
              };
            } else if (field === 'date') {
              return {
                headerName: field,
                field: field,
                valueFormatter: (params) => dateFormatter(params.value)
              };
            } else {
              return {
                headerName: field,
                field: field
              };
            }
          })
        }
      ]);
      console.log('columnDefs : ', columnDefs);
    } catch (error) {
      console.log(error);
    }
  };

  // 토탈 구하는 로직
  function getTotalRow(rowData: IOlympicData[]): IOlympicData[] {
    const initial: IOlympicData = {
      athlete: '',
      age: '',
      country: '',
      year: 0,
      date: '',
      sport: '',
      gold: 0,
      silver: 0,
      bronze: 0,
      total: 0
    };

    const rowTotal: IOlympicData = { ...initial };
    const deduplicationSport = new Set<string>();
    const deduplicationCountry = new Set<string>();
    let ageSum = 0;

    rowData.forEach((row) => {
      rowTotal.gold += row.gold;
      rowTotal.silver += row.silver;
      rowTotal.bronze += row.bronze;
      rowTotal.total += row.total;
      deduplicationSport.add(row.sport); // Add sport to the set to ensure uniqueness
      deduplicationCountry.add(row.country);
      ageSum += row.age as number;
    });
    rowTotal.athlete = `Total : ${rowData.length}`;
    rowTotal.age = parseFloat((ageSum / rowData.length).toFixed(1));
    rowTotal.sport = `총 : ${deduplicationSport.size.toString()} 종목`; // Set the number of unique sports
    rowTotal.country = `총 : ${deduplicationCountry.size.toString()}개 국`;

    return [rowTotal];
  }
  // rowData가 변할때마다 토탈Row 갱신함
  const pinnedBottomRowData = useMemo(() => {
    const totalRows = rowData && getTotalRow(rowData);
    console.log('totalRows-----------------------', totalRows);
    return rowData && rowData.length > 0 ? totalRows : [];
  }, [rowData]);

  return (
    <>
      <div style={containerStyle}>
        <div className='wrapper' style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={gridStyle} className='ag-theme-alpine'>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              gridOptions={createGridOptions({ dateFilter: true, excelExport: true })}
              suppressDragLeaveHidesColumns={true}
              onGridReady={onGridReady}
              domLayout='autoHeight' // 이거 있어야 height 됨
              // Row Pinning (Total관련)
              pinnedBottomRowData={pinnedBottomRowData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
