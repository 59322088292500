import React from 'react';
import CheckboxGrid from 'components/AgGrid/CheckboxGrid';
import MoveGrid from 'components/AgGrid/MoveGrid';
import TreeGrid from '../components/AgGrid/TreeGrid';
import ImportExportExcel from 'components/AgGrid/ImportExportExcel';
import ContextMenu from 'components/AgGrid/ContextMenu';
import ItemApiGrid from 'components/AgGrid/ItemApiGrid';
import DynamicGrid from 'components/AgGrid/DynamicGrid';
import InsertGrid from 'components/AgGrid/InsertGrid';

const AgGrid = () => {
  return (
    <div className=''>
      <div>DynamicGrid</div>
      <DynamicGrid />
      <div>InsertGrid</div>
      <InsertGrid />
      <div>ItemApiGrid</div>
      <ItemApiGrid />
      <div>ContextMenu</div>
      <ContextMenu />
      <div>Import Export Excel</div>
      <ImportExportExcel />
      <div>TreeGrid</div>
      <TreeGrid />
      <div>MoveGrid</div>
      <MoveGrid />
      <div>CheckboxGrid</div>
      <CheckboxGrid />
    </div>
  );
};
export default AgGrid;
