import React from 'react';

const Main = () => {
  return (
    <div className=''>
      <div>Main화면에 뭐가와야할것인가</div>
      <div>
        로그인 유무에 따라, 유= 지금화면으로 이동 / 무= 로그인창으로 이동
      </div>
    </div>
  );
};
export default Main;
