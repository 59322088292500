import React, { useRef, useState } from 'react';
import {
  StarFilled,
  StarOutlined,
  SearchOutlined,
  FileTextOutlined,
  FileAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  InsertRowBelowOutlined,
  DeleteRowOutlined,
  AppstoreAddOutlined,
  RollbackOutlined,
  PrinterOutlined,
  ExportOutlined,
  ImportOutlined
} from '@ant-design/icons';
import Checkbox from '@mui/material/Checkbox';
import { Col, Divider, Row } from 'antd';
import { TToolBarMnuItem } from '../../types/menu';
import './toolBar.css';

interface TToolBarItem {
  key: number;
  icon: React.ReactNode;
  label: string;
}

export const onSaveData = (gridApi: any) => {
  console.log('gridApi', gridApi);
  //gridApi가 없는거같..
  const modifiedRows = gridApi.current?.getEditingCells()?.map((cell: any) => cell.rowIndex) || [];
  console.log('modifiedRows: ', modifiedRows);
  const isValid = modifiedRows.every((rowIndex: number) => {
    const node = gridApi.current?.getRowNode(rowIndex);
    // TODO : 여기에 필수컬럼에 든 셀 값이 비어있는지 확인하는 로직 작성해야 함
    // 임시로 'value' 필드만 확인
    return node?.data?.value !== '';
  });
  console.log('isValid: ', isValid);

  if (!isValid) {
    // setSaveModalVisible(true);
    // 첫 번째 비어 있는 셀로 포커스 이동
    const firstEmptyCell = modifiedRows.find((rowIndex: number) => {
      const node = gridApi.current?.getRowNode(rowIndex);
      return node?.data?.value === '';
    });
    gridApi.current?.startEditingCell({
      rowIndex: firstEmptyCell,
      colKey: 'value' // 여기에 셀 키를 입력
    });
    return;
  }
};

export default function ToolBar() {
  const [current, setCurrent] = useState<number | null>(null);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const gridApi = useRef<any>(null);
  const customMenuItems: TToolBarMnuItem[] = [
    {
      key: 1,
      label: '조회',
      icon: <SearchOutlined />,
      checked: true
    },
    {
      key: 2,
      label: '저장',
      icon: <FileTextOutlined />,
      checked: true
    },
    {
      key: 3,
      label: '신규',
      icon: <FileAddOutlined />,
      checked: true
    },
    {
      key: 4,
      label: '복사',
      icon: <CopyOutlined />,
      checked: true
    },
    {
      key: 5,
      label: '삭제',
      icon: <DeleteOutlined />,
      checked: true
    },
    {
      key: 6,
      label: '이전',
      icon: <ArrowLeftOutlined />,
      checked: true
    },
    {
      key: 7,
      label: '다음',
      icon: <ArrowRightOutlined />,
      checked: true
    },
    {
      key: 8,
      label: '행 추가',
      icon: <AppstoreAddOutlined />,
      checked: true
    },
    {
      key: 9,
      label: '행 복사',
      icon: <InsertRowBelowOutlined />,
      checked: true
    },
    {
      key: 10,
      label: '행 삭제',
      icon: <DeleteRowOutlined />,
      checked: true
    },
    {
      key: 11,
      label: '취소',
      icon: <RollbackOutlined />,
      checked: true
    },
    {
      key: 12,
      label: '출력',
      icon: <PrinterOutlined />,
      checked: true
    }
  ];
  const fixedMenuItems: TToolBarMnuItem[] = [
    {
      key: 13,
      label: '내보내기',
      icon: <ExportOutlined />,
      checked: true
    },
    {
      key: 14,
      label: '가져오기',
      icon: <ImportOutlined />,
      checked: true
    }
  ];

  const renderToolBarItem = (item: TToolBarItem) => {
    const handleClick = (key: number) => {
      console.log('click ', key);
      setCurrent(key);
      if (key === 2) {
        console.log('저장버튼 클릭!!!!', onSaveData(item));
        // 저장 버튼 클릭 시
        onSaveData(item);
      }
      if (key === 8) {
        console.log('행추가버튼 클릭!!!!');
      }
    };
    return (
      <Col
        className='toolbar-col'
        span={1.9}
        key={item.key}
        onClick={() => handleClick(item.key)}
        onMouseEnter={(e) => (e.currentTarget.style.color = '#3381ca')}
        onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
      >
        {item.icon}
        <span className='toolbar-col-label'>{item.label}</span>
      </Col>
    );
  };

  return (
    <div className='toolbar-row-container'>
      <Row className='toolbar-row' gutter={[8, 8]}>
        {/* 즐겨찾기 */}
        <Col span={0.5}>
          <Checkbox
            // ToDo: API연결하고 icon 주석 활성화하기
            // icon={isChecked ? <StarFilled /> : <StarOutlined />}
            // 여기도 별 골드색상으로 통일함
            icon={<StarOutlined style={{ color: '#ffd700', fontSize: '1.2rem' }} />}
            checkedIcon={<StarFilled style={{ color: '#ffd700', fontSize: '1.2rem' }} />}
          />
        </Col>
        {/* 구분선 */}
        <Divider type='vertical' style={{ backgroundColor: 'white' }} />
        {/* 커스텀 가능한 Menu */}
        {customMenuItems.map(renderToolBarItem)}
        {/* 구분선 */}
        <Divider type='vertical' style={{ backgroundColor: 'white' }} />
        {/* 고정메뉴 */}
        {fixedMenuItems.map(renderToolBarItem)}
      </Row>
    </div>
  );
}
