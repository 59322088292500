import React, { useState, useRef, useCallback } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { createGridOptions } from './CommonFunction';
import * as XLSX from 'xlsx';

export default function ImportExportExcel() {
  const gridRef = useRef<AgGridReact>(null);
  const [fileName, setFileName] = useState<string>('');
  const [defaultColDef] = useState<ColDef>({
    resizable: true,
    flex: 1
  });

  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<any | null>(null);

  const onGridReady = (params: { api: GridApi; columnApi: any }) => {
    console.log('import grid onGridReady : ', params);
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (!fileList || fileList.length === 0) return;

    const file = fileList[0];
    setFileName(file.name); // 파일 이름을 상태로 설정
    const reader = new FileReader();
    reader.onload = (e) => {
      const bstr = e.target?.result as string;
      const workBook = XLSX.read(bstr, { type: 'binary' });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      importDataToGrid(fileData);
    };
    reader.readAsBinaryString(file);
    // event.target.value = file.name;
  };

  const importDataToGrid = (fileData: any) => {
    console.log('import grid fileData : ', fileData);
    const headers = fileData[0];
    const columnDefs = getColumnDefs(headers);
    gridApiRef.current!.setColumnDefs(columnDefs);

    const gridData = fileData.slice(1);
    const rowsToAdd = populateGridData(gridData, headers);
    appendToGridData(rowsToAdd);
  };

  const getColumnDefs = (headers: any[]): ColDef[] => {
    return headers.map((header, index) => ({
      headerName: header,
      field: header,
      editable: index < 10
    }));
  };

  const populateGridData = (gridData: any[][], headers: any[]): any[] => {
    console.log('import grid gridData : ', gridData);
    console.log('import grid headers : ', headers);
    return gridData.map((row) => {
      const rowObject: any = {};
      headers.forEach((header, index) => {
        rowObject[header] = row[index];
      });
      return rowObject;
    });
  };

  const appendToGridData = (rowsToAdd: any[]) => {
    console.log('import grid rowsToAdd : ', rowsToAdd);
    let count = 0;
    gridApiRef.current!.forEachNode(() => {
      count++;
    });
    gridApiRef.current!.applyTransaction({
      add: rowsToAdd,
      addIndex: count
    });
  };

  // 빈 배열을 설정하여 행 데이터 지우는 함수
  const onBtnClearRowData = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.setRowData([]); // 행 데이터를 빈 배열로 설정 (그리드 지움)
      setFileName(''); // 파일이름 지움
    }
  }, []);

  //  엑셇 Export
  const onExcelExportBtn = useCallback(() => {
    gridRef.current!.api.exportDataAsExcel();
  }, []);
  return (
    <>
      <div>
        {fileName ? (
          <p>선택된 파일 : {fileName}</p>
        ) : (
          <input
            type='file'
            onChange={handleFileChange}
            accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          />
        )}
      </div>
      {fileName && (
        <>
          <button onClick={onBtnClearRowData}>파일 리셋</button>
          <button onClick={onExcelExportBtn} style={{ marginBottom: '5px', fontWeight: 'bold' }}>
            Export to Excel
          </button>
        </>
      )}

      <div className='ag-theme-alpine' style={{ height: 350, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          gridOptions={createGridOptions({ dateFilter: true, excelExport: true })}
          enableCellTextSelection={false} //셀 내의 텍스트를 선택할 수 있도록 설정 .true=클립보드 비활성화, 선택한 텍스트만 복사
          enterNavigatesVertically={true} // Enter 키를 누르면 아래 셀로 이동
          enterNavigatesVerticallyAfterEdit={true} // Enter 키를 누르면 아래 셀로 이동 (*AfterEdit)
          rowSelection={'multiple'} // id쪽 체크박스 여러개 할수있는 옵션!!!! (*완전 필요함)
          copyHeadersToClipboard={false} // 클립보드로 복사할 때 머리글도 포함 = true
          suppressCopySingleCellRanges={true} // 단일 셀만 있는 범위를 선택할 때 범위 대신 행을 복사하려면 true
          suppressCutToClipboard={false}
          enableRangeSelection={true} // 범위 선택을 활성화
        />
      </div>
    </>
  );
}
