import { Cookies } from 'react-cookie';
import { TGetCookieProps, TSetCookieProps, TRemoveCookieProps } from '../types/cookie';

const cookies = new Cookies();

/**
 * react-cookie의 set 메서드를 사용하여 쿠키를 설정하는 함수
 * @param {SetCookieProps} name 쿠키의 key 값
 * @param {SetCookieProps} value 쿠키의 value 값
 * @param {SetCookieProps} options 쿠키의 설정 옵션
 * @returns {void}
 */
export const setCookie = ({ name, value, options }: TSetCookieProps): void => {
  return cookies.set(name, value, { ...options });
};

/**
 * react-cookie의 get 메서드를 사용하여 쿠키를 가져오는 함수
 * @param {GetCookieProps} name 가져올 쿠키의 key 값
 * @param {GetCookieProps} options 쿠키 가져오기 옵션
 * @returns {string | undefined} 해당 key에 해당하는 value 값
 */
export const getCookie = ({ name, options }: TGetCookieProps): string | undefined => {
  return cookies.get(name, { ...options });
};

/**
 * react-cookie의 remove 메서드를 사용하여 쿠키를 삭제하는 함수
 * @param {RemoveCookieProps} name 삭제할 쿠키의 key 값
 * @param {RemoveCookieProps} options 쿠키의 설정 옵션
 * @returns {void}
 */
export const removeCookie = ({ name, options }: TRemoveCookieProps): void => {
  return cookies.remove(name, { ...options });
};
