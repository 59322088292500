import React from 'react';
import { Modal, Input } from 'antd';

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode; // children으로 Search 컴포넌트를 받음
  modalWidth?: string | number;
}

export default function CustomModal({ title, isOpen, onClose, children, modalWidth }: ModalProps) {
  return (
    <Modal title={title} open={isOpen} footer={null} onCancel={onClose} width={modalWidth || '800px'}>
      {children} {/* Search 컴포넌트를 받아 렌더링 */}
    </Modal>
  );
}
