import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CustomModal from './Modal/CustomModal';
import type { SearchProps } from 'antd/es/input/Search';

export default function SearchMenuModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Search } = Input;

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    console.log(info?.source, value);
  };

  return (
    <>
      <SearchOutlined
        style={{
          fontSize: '1.5rem',
          color: 'white'
        }}
        onClick={showModal}
      />

      <CustomModal title='메뉴 검색' isOpen={isModalOpen} onClose={handleCancel}>
        <Search
          placeholder='메뉴를 입력하세요.'
          allowClear
          onSearch={onSearch} // 돋보기버튼 클릭 또는 엔터 입력 시 호출됨
          style={{ width: '90%' }}
        />
      </CustomModal>
    </>
  );
}
