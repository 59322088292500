import { TSetTokensInCookiesProps } from '../../types/cookie';
import { removeCookie, setCookie } from '../cookie';
import { encrypt } from './crypto';

/**
 * 암호화된 accessToken과 refreshToken을 쿠키에 설정합니다.
 *
 * @param {string} accessToken - 'accessToken' 쿠키에 저장될 액세스 토큰
 * @param {string} refreshToken - 'refreshToken' 쿠키에 저장될 리프레시 토큰
 *
 * @returns {void}
 */
export const setTokensInCookies = ({ accessToken, refreshToken }: TSetTokensInCookiesProps) => {
  const cookieOptions: any = {
    path: '/' // 모든 페이지에서 쿠키 접근 가능
  };

  // accessToken을 암호화하여 쿠키에 설정
  setCookie({
    name: 'accessToken',
    value: encrypt(accessToken),
    options: cookieOptions
  });

  // refreshToken을 암호화하여 쿠키에 설정
  setCookie({
    name: 'refreshToken',
    value: encrypt(refreshToken),
    options: cookieOptions
  });
};

/**
 * 암호화된 accessToken과 refreshToken을 쿠키에서 제거합니다.
 *
 * @returns {void}
 */
export const removeTokensFromCookies = (): void => {
  removeCookie({ name: 'accessToken', options: { path: '/' } });
  removeCookie({ name: 'refreshToken', options: { path: '/' } });
};
