import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Modal, message } from 'antd';
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { ColDef, RowNode, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './commonFunction.css';

interface DataRow {
  id: number;
  email: string;
  phoneNumber: number;
  name: string;
  date: Date | null;
}

export default function InsertGrid() {
  const [rowData, setRowData] = useState<DataRow[]>([]);
  const gridApi = useRef<any>(null);
  // Ag-Grid에서 사용할 삭제 컴포넌트
  const actionCellRenderer = (data: any) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button icon={<DeleteOutlined />} onClick={() => handleDeleteRow(data.data)} />
    </div>
  );
  // Ag-Grid 컬럼 정의
  const [columnDefs, setcolumnDefs] = useState<ColDef<DataRow>[]>([
    { headerName: 'ID', field: 'id', width: 50, editable: false },
    { headerName: 'Email', field: 'email', editable: true, headerClass: 'required-header' },
    { headerName: 'Phone Number', field: 'phoneNumber', editable: true, headerClass: 'required-header' },
    { headerName: 'Name', field: 'name', editable: true, headerClass: 'required-header' },
    {
      headerName: 'Date',
      field: 'date',
      editable: true,
      headerClass: 'required-header',
      valueFormatter: (params: ValueFormatterParams<any, Date>) => {
        if (!params.value) {
          return '';
        }
        const month = params.value.getMonth() + 1;
        const day = params.value.getDate();
        return `${params.value.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      },
      cellEditor: 'agDateCellEditor'
    },
    {
      headerName: 'Delete',
      width: 80,
      cellRenderer: actionCellRenderer
    }
  ]);

  // 다음 ID 생성
  const getNextId = () => {
    return rowData.length + 1;
  };

  // 행 추가
  const handleAddRow = () => {
    const newItem: DataRow = {
      id: getNextId(),
      email: '',
      phoneNumber: 0,
      name: '',
      date: new Date() // 현재 날짜로 초기화
    };
    const updatedRows = [...rowData, newItem];
    setRowData(updatedRows);
    console.log('handleAddRow rowData', rowData);
  };

  // 행 삭제
  const handleDeleteRow = (row: DataRow) => {
    console.log('제할 ID:', row, 'rowData', rowData);
    const updatedRows = rowData.filter((dataRow) => dataRow.id !== row.id);
    console.log('handleDeleteRow rowData', updatedRows);
    row && setRowData(updatedRows);
  };

  // 저장 버튼 클릭
  const handleSaveData = () => {
    // 모든 행을 확인하여 빈 값을 가진 행이 있는지 체크
    const hasEmptyCells = rowData.some((row) => {
      return !row.email || !row.phoneNumber || !row.name || !row.date;
    });

    if (hasEmptyCells) {
      message.error('모든 필드를 입력해주세요.');
      // 첫 번째 빈 셀에 포커싱
      focusFirstEmptyCell();
      return;
    }

    // 저장 로직을 추가하세요 (예: API 호출 등)
    console.log('저장할 데이터:', rowData);
    message.success('데이터가 성공적으로 저장되었습니다.');
  };

  // 첫 번째 빈 셀에 포커싱하는 함수
  const focusFirstEmptyCell = () => {
    // gridApi가 존재하지 않으면 return
    if (!gridApi.current) return;

    // 비어있는 첫 번째 행을 찾음
    const emptyRow = rowData.find((row) => {
      // email, phoneNumber, name, date 중 하나라도 비어있으면 true 반환
      return !row.email || !row.phoneNumber || !row.name || !row.date;
    });

    // 비어있는 행이 없으면 return
    if (!emptyRow) return;

    // 각 노드를 순회하며 해당 비어있는 행을 찾고 포커싱
    gridApi.current.forEachNode((node: RowNode) => {
      if (node.data.id === emptyRow.id) {
        // 비어있는 열을 찾음
        const columnToFocus = Object.keys(emptyRow).find((key) => !emptyRow[key as keyof DataRow]);
        if (columnToFocus) {
          const column = gridApi.current?.getColumnDef(columnToFocus as keyof DataRow);
          if (column) {
            gridApi.current.ensureColumnVisible(column); // 열이 보이도록 스크롤
            node.setSelected(true, true); // 노드 선택
          }
        }
      }
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <Button type='primary' icon={<PlusOutlined />} onClick={handleAddRow}>
          행 추가
        </Button>
        <Button type='primary' icon={<SaveOutlined />} onClick={handleSaveData} style={{ marginLeft: '10px' }}>
          저장
        </Button>
      </div>
      <div className='ag-theme-alpine' style={{ height: '400px', width: '100%', marginTop: '20px' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={(params) => (gridApi.current = params.api)}
          suppressClickEdit={true}
          rowSelection='multiple'
          // 필요에 따라 추가적인 Ag-Grid 설정
        />
      </div>
    </div>
  );
}
