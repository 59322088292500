import React, { useState, MouseEvent as ReactMouseEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { Dropdown } from 'antd';
import { useAuth } from 'contexts/AuthContext';
import type { MenuProps } from 'antd';
import './noticeModal.css';

// MenuInfo 인터페이스 정의
interface MenuInfo {
  key: string;
  keyPath: string[];
  domEvent: ReactMouseEvent<HTMLElement> | ReactKeyboardEvent<HTMLElement>;
  item?: any; // item은 존재할 수 있지만, 현재 구조상 선택적임
}
export default function NoticeModal() {
  const { isLoggedIn, logout } = useAuth();

  // 초기 items 배열을 useState로 설정
  const [items, setItems] = useState([
    {
      key: '1',
      label: (
        <div className='notice'>
          <h4 className='title'>주문등록</h4>
          <div className='detail'>
            NS201801010001
            <span className='time'>1시간 전</span>
          </div>
          <button className='delete' onClick={() => handleDelete('1')}>
            삭제
          </button>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div className='notice cancel'>
          <h4 className='title'>주문취소</h4>
          <div className='detail'>
            NS201801010001
            <span className='time'>1시간 전</span>
          </div>
          <button className='delete' onClick={() => handleDelete('2')}>
            삭제
          </button>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div className='notice delivery'>
          <h4 className='title'>배송완료</h4>
          <div className='detail'>
            NS201801010001
            <span className='time'>1시간 전</span>
          </div>
          <button className='delete' onClick={() => handleDelete('3')}>
            삭제
          </button>
        </div>
      )
    }
  ]);
  // 삭제 핸들러 함수
  const handleDelete = (key: string) => {
    setItems((prevItems) => prevItems.filter((item) => item.key !== key));
  };

  return (
    <Dropdown menu={{ items }} placement='topRight' arrow>
      {/* ToDo : API 정보 넣기 = count랑 알람 items 정보들 */}
      {/* ToDo : API호출을 언제언제 할것인가 생각 */}
      <div className='notification-container' aria-label='Notifications'>
        <span className='material-symbols-outlined notification-icon' style={{ color: 'black' }}>
          notifications
        </span>
        <sup className='notification-badge'>{items.length}</sup>
      </div>
    </Dropdown>
  );
}
